
<template>
  <div class="_100 nobg">
    <div class="form-block no-margin w-form">
      <div v-on:submit.prevent class="field-collection">
        <div class="field-div horizontal">
          <select
            :value="setOption()"
            @change="changeOption"
            ref="option"
            class="link-type w-select"
          >
            <option v-for="(option, index) in field.options" :key="index">
              {{ option }}
            </option>
          </select>

          <template>
            <input
              v-on:keyup.enter="doSubmit"
              @change="changeField"
              @keyup="changeField"
              ref="field"
              :value="field.value"
              :placeholder="placeholder"
              :maxlength="length"
              required="true"
              class="text-field url w-input"
              type="text"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "../../GlobalValues.json";
import methods from "../../Methods/General.js";

export default {
  props: ["info", "field_input", "index", "isChange"],
  data() {
    return {
      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholders"],

      placeholder: null,
    };
  },

  computed: {
    field() {
      return this.field_input;
    },
    length() {
      return this.input_length[this.field.option];
    },
  },

  created() {
    this.placeholder = methods.setPlaceholder(this.field.option, this.info.uid, this.placeholders);
  },

  methods: {
    changeOption() {
      var variable = {
        index: this.index,
        value: this.$refs.option.value,
      };

      this.$emit("changeOption", variable);
      this.placeholder = methods.setPlaceholder(this.field.option, this.info.uid, this.placeholders);
    },

    changeField() {
      var variable = {
        index: this.index,
        value: this.$refs.field.value,
      };

      this.$emit("changeField", variable);
    },

    setOption() {
      if (this.isChange) {
        this.field.value = methods.split_for_input(
          this.field.option,
          this.field.value
        );
        return this.field.option;
      } else {
        return this.field.option;
      }
    },



    async doSubmit() {
      await this.changeOption();
      await this.changeField();
      this.$emit("submit", true);
    },
  },
};
</script>