
<template>
    <div>
        <template v-if="!next">
            <div class="background">
                <Header/>

                <div class="page-body">
                    <div class="title-block">
                        <div class="columns-3 w-row">
                            <div class="left w-col w-col-6">
                                <div class="text-div">
                                    <h1 class="heading1 left">{{page_name}}</h1>
                                </div>
                            </div>
                            <div class="right w-col w-col-6">
                                <div class="text-div right">
                                    <p class="paragraph">Go to</p>
                                    <a href="/login" class="paragraph bold marge">login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="transp-body data-fillin">
                        <div class="register-step-div">
                        <img src="images/step1of2.png" loading="lazy" width="101" alt="">
                        </div>
                        <div class="form-div step1">
                        <div class="form-block w-form">
                            <div class="paragraph left">{{field_names.url}}</div>
                            <div class="form-3">
                                <input disabled type="text" class="text-field nofocusborder left w-input" maxlength="15" placeholder="amigo.bio/">
                                <input :style="{ 'border-color': border.url }" @change="changeUrl()" :value="user_info.url" ref="url" type="url" class="text-field nofocusborder right w-input" :maxlength="input_length.url" required="true" autocorrect="off" autocapitalize="off">
                            </div>
                            <div class="div-block-59">
                            <div class="div-block-60">
                                <img v-if="error.url==='' & success.url===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.url!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.url!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <div class="message">url is available</div>
                            </div>
                            </div>
                        </div>
                        <div class="form-block no-margin w-form">
                            <div class="form-2">
                            <div class="paragraph left">{{field_names.email}}</div>
                            <input @change="checkEmail()" :style="{ 'border-color': border.email }" :value="user_info.email" ref="email" type="email" class="text-field marge-between w-input" :maxlength="input_length.email" :placeholder="field_names.email" required="true" autocorrect="off" autocapitalize="off">
                            <div class="div-block-59">
                                <div class="div-block-60">
                                <img v-if="success.email==='' & error.email===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.email!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.email!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <div class="message">email is available</div>
                                </div>
                            </div>
                            <div class="paragraph left">{{field_names.password}}</div>
                            <input @change="checkPassword()" :style="{ 'border-color': border.password1 }" :value="user_info.password1" ref="password1" type="password" class="text-field marge-between w-input" :maxlength="input_length.password" :placeholder="placeholders.password" required="true" autocorrect="off" autocapitalize="off">
                            <div class="div-block-59">
                                <div class="div-block-60">
                                <img v-if="error.password_min==='' & success.password_min===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_min!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_min!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">at least 8 characters</div>
                                </div>
                                <div class="div-block-60">
                                <img v-if="error.password_digit==='' & success.password_digit===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_digit!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_digit!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">contains one digit</div>
                                </div>
                                <div class="div-block-60">
                                <img v-if="error.password_lower==='' & success.password_lower===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_lower!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_lower!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">contains one lower character</div>
                                </div>
                                <div class="div-block-60">
                                <img v-if="error.password_upper==='' & success.password_upper===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_upper!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_upper!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">contains one UPPER case character</div>
                                </div>
                            </div>
                            <div class="paragraph left">{{field_names.repeat_password}}</div>
                            <input v-on:keyup.enter="next_page" @change="checkPass2()" :style="{ 'border-color': border.password2 }" :value="user_info.password2" ref="password2" type="password" class="text-field marge-between w-input" :maxlength="input_length.password" :placeholder="placeholders.password" required="true" autocorrect="off" autocapitalize="off">
                            <div class="div-block-59">
                                <div class="div-block-60">
                                <img v-if="error.password_equal==='' & success.password_equal===''" src="images/check-white.svg" loading="lazy" height="" class="image-26">
                                <img v-if="error.password_equal!==''" src="images/close-01.svg" loading="lazy" height="" class="image-26">
                                <img v-if="success.password_equal!==''" src="images/check.svg" loading="lazy" height="" class="image-26">
                                <div class="message">passwords are equal</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div>
                            <a @click="next_page()" class="submit-button w-inline-block">
                            <div class="instruction right">{{btn}}</div>
                            <img src="images/right-arrow.png" loading="lazy" class="arrow">
                            </a>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </template>
        <template v-else>
            <Step2
            :userInfo="user_info"
            :custom_url="custom_url"
            :product_url="product_url"
            :input_image="image"
            @back="saveInfo"/>
        </template>
    </div>
</template>


<script>
import json from '../General/GlobalValues.json'

import validate from '../General/Methods/Validate.js'
import db_url from '../General/DB/url.js'
import db_auth from '../General/DB/auth.js'


import Step2 from './Register2.vue'

import Header from '../General/Elements/Headers/Header1.vue'

export default {
    components: {
        Step2,
        Header,
    },
    props: [
      'activationcode',
      'p_url',
      'c_url'
    ],
    data() { 
        return {
            user_info: {
                email: "",
                password1: "",
                password2: "",
                url: "",

            },
            
            product_url: {
                value: null,
                code: null,
                type: 'product',
            },

            custom_url: {
                value: null,
                code: null,
                type: 'internet',
            },

            
            input_length: json["input_length"],
            field_names: json["field_names"],
            placeholders: json["placeholders"],
            

            error: {
                url: "",
                email: "",
                password_min: "",
                password_upper: "",
                password_lower: "",
                password_digit: "",
                password_equal: "",

            },

            success:{
                url: "",
                email: "",
                
                password_min: "",
                password_upper: "",
                password_lower: "",
                password_digit: "",
                password_equal: "",

            },
            
            next: false,
            page_name: "Register",
            btn: "Last step",

            border: {
                url: "",
                email: "",
                
                password_min: "",
                password_upper: "",
                password_lower: "",
                password_digit: "",
                password_equal: "",
            },

            image: '',
            

            
        };
    },
  
  

  async created() {
      
      this.setInitialUrl();
      this.checkUrl();

  },
  methods: {
    setInitialUrl() {
        //check if there is a product
        if (this.p_url!==null) {
            this.product_url.value = this.p_url;
            this.user_info.url = this.p_url;
            this.product_url.code = this.activationcode;
        } else {
            this.product_url = null;
            this.custom_url.value = this.c_url;
            this.user_info.url = this.c_url;
        } 

    },
    async checkUrl(){
        const option = await db_url.available(this.user_info.url, this.activationcode);
        if (option === 1 || option === 2) {
            
            this.error.url = "";
            this.success.url = "https://amigo.bio/" + this.user_info.url + " is available";
            this.border.url = '#00c9aa';
            return true;
        } else if(option === 3) {
            this.success.url = ""
            this.error.url = "https://amigo.bio/" + this.user_info.url + " is already taken";
            this.border.url = '#ff0000';
            return false;
        } else if (option === 4) {
            this.success.url = ""
            this.error.url = "https://amigo.bio/" + this.user_info.url + " is not possible. Only numbers, letters, hypens, periods, spaces and underscores are allowed";
            this.border.url = '#ff0000';
            return false;
        }

        return false;
        
    },

    async changeUrl() {
        if (this.user_info.url !== this.$refs.url.value) {
            await this.setCustomUrl(this.$refs.url.value.toLowerCase());
            
            this.checkUrl();
        }

    },

    setCustomUrl(url) {
        this.custom_url.value = url;
        this.user_info.url = url;
        
    },

    




    
    saveInfo(array) {
        this.next = false;
        this.user_info = array[0];
        this.image = array[1];
    },

    


    async next_page() {
        if(await this.checkVariables()) {
            this.next = true;
        }

    },

    async checkEmail() {
        var email = this.$refs.email.value;
        this.user_info.email = this.$refs.email.value;

    
        if (!validate.email(email)) {
            this.error.email = "Your email " + email + " is not a real email adress!"
            this.border.email = "#ff0000"
            return false;
        } else {
            this.error.email = ""
            this.border.email = ""
        }
        
        if (await db_auth.mailAvailable(email)) {
            this.success.email = "success!";
            this.border.email = '#00c9aa';
        } else {
            this.error.email = "Sorry this email does already exist!";
            this.border.email = "#ff0000"
            return false;
        }

        

            

        return true;
    },

    async checkPassword(){
        
        this.user_info.password1 = this.$refs.password1.value;

        var password_check = await validate.password(this.$refs.password1.value)

        var isCorrect = await this.validatePass1(password_check)
            
        await this.checkPass2();
        
        return isCorrect;
        
    },

    async validatePass1(password_check) {
        var password = true;
        if (password_check.includes('upper')) {
            this.error.password_upper = "The password doesnt contain upper"
            this.success.password_upper = ""
            password = false;
        } else {
            this.error.password_upper = ""
            this.success.password_upper = "ys"
        }

        if (password_check.includes('lower')) {
            this.error.password_lower = "The passwords doesnt contain lower"
            this.success.password_lower = ""
            password = false;
        } else {
            this.error.password_lower = ""
            this.success.password_lower = "ys"
        }


        if (password_check.includes('min')) {
            this.error.password_min = "err"
            this.success.password_min = ""
            password = false;
        } else {
            
            this.error.password_min = ""
            this.success.password_min = "yes"
        }

        if (password_check.includes('digit')) {
            this.error.password_digit = "err"
            this.success.password_digit = ""
            password = false;
        } else {
            this.error.password_digit = ""
            this.success.password_digit = "yes"
        }

       
        
        if (password) {
            this.border.password1 = "#00c9aa" 
            return true;
        }  else {
            this.border.password1 = "#ff0000"
            return false;
        }

        
    },

    async checkPass2() {
        this.user_info.password2 = this.$refs.password2.value;
        var password1 = this.$refs.password1.value;
        var password2 = this.$refs.password2.value;

        if (password2 === '') {
            return false;
            
        } else if ( password1 !== password2){
            
            this.error.password_equal = "The passwords are not equal!"
            this.border.password2 = "#ff0000"
            this.success.password_equal = ""
            
            return false;
        } else {
            this.error.password_equal = "";
            this.border.password2= "#00c9aa"
            this.success.password_equal = "yes"
        }

        return true;
    },

    async checkVariables() {

        

        if (!await this.checkUrl()) {
            return false;
        }

        

        if (!await this.checkEmail()) {
            return false;
        }


        if (!await this.checkPassword()) {
            return false;
        }
        

        if (! await this.checkPass2()) {
            return false;
        }
        return true;
    },

    


    
      
  },
  
};
</script>
