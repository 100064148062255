
import json from '../../GlobalValues.json'
import {db} from '../../../firebase'


export default {

    //Data
    async setData(uid, data) {
        await db.collection(json['collections'].users).doc(uid).set(data)
        .catch((err) => {
            throw err.message;
        });
    },

    async deleteData(uid) {
        return await db.collection(json['collections'].users).doc(uid).delete()
        .catch((err) => {
            throw err.message;
        })
    },

    async getData(uid) {
        return await db.collection(json['collections'].users).doc(uid).get()
        .then(doc => {
            var data = doc.data();
            data.uid = doc.id;
            return data;
        })
        .catch(() => {
            throw "It seems that your account is not available"
        });

    },

    


    async getUrl(uid) {
       
        return await db.collection(json['collections'].users).doc(uid).get()
        .then(doc => {
            return doc.data().url;
        })
        .catch(()=>{
            throw "Sorry can't find url"
        })
    },

    async updateData(uid, data) {

        return await db.collection(json['collections'].users).doc(uid).update(data)
        .catch(() => {
            throw "Sorry couldn't update your files"
        });
    },

}