

import json from '../../GlobalValues.json'
import {db} from '../../../firebase'


export default {

    //public socials
    async setData(uid, data, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(group).set({
            data: data,
        })
        .catch((err) => {
            throw err.message;
        })
    },

    async updateData(uid, socials, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(group).update({
            data: socials
        })
        .catch((err) => {
            throw err.message;
        })
    },

    async resetData(uid, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(group).update({data: []})
        .catch(() => {
            throw "Sorry couldn't reset your folder"
        });
    },

    async deleteData(uid, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(group).delete()
        .catch(() => {
            throw "Sorry couldn't delete your folder"
        });
    },

    async getData(uid, group) {
        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(group).get()
        .then(doc => {
            return doc.data();
        })
        .catch(() => {
            throw "It seems that you don't have any socials!"
        });
    },

}