var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"img-div"},[_c('div',{staticClass:"upload-pp-field large"},[(_vm.image === '')?[_c('img',{attrs:{"src":"images/add-photo.png","loading":"lazy"}})]:_vm._e(),(_vm.editMode)?[_c('div',{staticClass:"setimage edit w-inline-block",style:({
            'background-image': 'url(' + _vm.image + ')',
            'background-position': '50% 50%',
            'background-size': 'cover',
          }),on:{"click":function($event){return _vm.$refs.inpFile.click()},"mouseover":function($event){_vm.img_hover = true},"mouseleave":function($event){_vm.img_hover = false}}},[(_vm.img_hover)?[_c('div',{staticClass:"div-block-23",style:('background-color:' + _vm.color_rgba())},[_c('img',{attrs:{"src":"images/add-photo.png","loading":"lazy","alt":""}})])]:_vm._e()],2)]:[_c('div',{staticClass:"setimage w-inline-block",style:({
            'background-image': 'url(' + _vm.image + ')',
            'background-position': '50% 50%',
            'background-size': 'cover',
          })},[(_vm.img_hover)?[_vm._m(0)]:_vm._e()],2)]],2)]),_c('input',{ref:"inpFile",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/x-png,image/jpeg"},on:{"change":_vm.onFileChange}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"div-block-23"},[_c('img',{attrs:{"src":"images/add-photo.png","loading":"lazy","alt":""}})])}]

export { render, staticRenderFns }