
<template>
    <div class="background _100vh">
        <Header />
        <div class="page-body-2 center">
            <div class="form-div width-cap">
                <h1 class="heading1 bold">Welcome again</h1>
                <p class="paragraph">Login to your account</p>
                <div class="form-block w-form">
                    <form>
                        <input ref="email" placeholder="Email" type="text" class="text-field marge-between w-input"
                            :maxlength="input_length.email">
                        <input ref="password" placeholder="Password" type="password"
                            class="text-field marge-between w-input" :maxlength="input_length.password">
                        <div class="div-block">
                            <div class="columns w-row">
                                <div class="w-col w-col-6">

                                </div>
                                <div class="w-col w-col-6">
                                    <div class="rightalign-div">
                                        <a href="/reset-password" class="paragraph small">Forgot password?</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div>
                    <a @click="login" class="submit-button w-button">Login</a>
                </div>

                <template v-if="error !== ''">
                    <div>
                        <div id="error-message" class="error-message">{{ error }}</div>
                    </div>
                </template>
            </div>
        </div>

        <LoginFooter />

    </div>
</template>


<script>

import { auth } from '../firebase'

import LoginFooter from './Elements/Login/LoginFooter.vue'

import db_auth from '../General/DB/auth.js'

import Header from './Elements/Headers/Header1.vue'

import json from '../General/GlobalValues.json'


export default {
    components: {
        LoginFooter,
        Header,
    },
    data() {
        return {
            error: '',
            input_length: json["input_length"],

        };
    },
    created() {
        db_auth.checkState(this.$router);
    },

    methods: {
        login() {
            var email = this.$refs.email.value;
            var password = this.$refs.password.value;
            auth.signInWithEmailAndPassword(email, password)
                .then(() => {
                    this.$router.push("/")
                })
                .catch((err) => {
                    this.error = err.message;
                });
        },




    },

};
</script>
