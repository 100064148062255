<template>
  <div>
    <div class="img-div">
      <div class="upload-pp-field large">
        <template v-if="image === ''">
          <img src="images/add-photo.png" loading="lazy" />
        </template>

        <template v-if="editMode">
          <div
            class="setimage edit w-inline-block"
            :style="{
              'background-image': 'url(' + image + ')',
              'background-position': '50% 50%',
              'background-size': 'cover',
            }"
            @click="$refs.inpFile.click()"
            @mouseover="img_hover = true"
            @mouseleave="img_hover = false"
          >
            <template v-if="img_hover">
              <div
                :style="'background-color:' + color_rgba()"
                class="div-block-23"
              >
                <img src="images/add-photo.png" loading="lazy" alt="" />
              </div>
            </template>
          </div>
        </template>

        <template v-else>
          <div
            :style="{
              'background-image': 'url(' + image + ')',
              'background-position': '50% 50%',
              'background-size': 'cover',
            }"
            class="setimage w-inline-block"
          >
            <template v-if="img_hover">
              <div class="div-block-23">
                <img src="images/add-photo.png" loading="lazy" alt="" />
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <input
      ref="inpFile"
      @change="onFileChange"
      type="file"
      style="display: none"
      accept="image/x-png,image/jpeg"
    />
  </div>
</template>

<script>
import methods from "../Methods/General.js";

export default {
  data: () => {
    return {
      img_hover: false,
    };
  },

  props: ["image", "editMode", "color"],

  methods: {
    async onFileChange(e) {
      this.$emit("image", e.target.files[0]);
    },
    color_rgba() {
      return methods.hexToRgb(this.color, 0.4);
    },
  },
};
</script>