<template>
  <div class="direct-link">
    <div class="text-block-10">Your profile will be directly linked to:</div>
    <div class="txt-div fullwidth">
      <div class="white big center fullwidth">{{ title }}</div>
    </div>
  </div>
</template>


<script>


export default {
    props: ["title"]
}
</script>
