
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import firebase from 'firebase/compat/app'
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

// Config
import config from './config'

// Initializing firebase app
const app = firebase.initializeApp(config)
const analytics = getAnalytics(app);
const perf = getPerformance(app);


const db = app.firestore()
const auth = app.auth()

// var signPages = [
//     'sign-up',
//     'login',
//     'reset-password'
// ];

// var privatePages = [
//     'register2',
//     'account',
//     '',
// ];

// var toDB = [
//     'added'
// ]

// auth.onAuthStateChanged(async (user) => {
//     // var url = "";
//     // var currentPath = location.href.split("/").pop();

//     console.log(user)
//     console.log(this.$route.fullPath.split("/").pop())
//     // if(user) {
        
//     //     if(signPages.includes(currentPath)){
//     //         // //if logged in and at register/sign up page
//     //         // const url_parts = window.location.href.split("/");
//     //         // const url_length = url_parts.length;
//     //         // const key_value = url_parts.pop().split("=");

//     //         // if ((url_length === 2) && (key_value.length === 2)) {
//     //         //     //if id is given save that contact
//     //         //     const id = key_value.pop();
//     //         //     url = "#/added?" + id;
//     //         // } else {
//     //             //if no id is given set to configuration page
//     //         // url = "#/";
//     //         // }
//     //         window.location.replace("#/");
//     //     }

//     // } else {
//     //     if(privatePages.includes(currentPath)){
//     //         //if on private page and not logged in
//     //         //go to login screen
//     //         window.location.replace('#/login');
//     //     }// } else if(toDB.includes(currentPath)) {
//     //         //if want to save contact, but not logged in
//     //         //go to loginpage with saveContact id
//     //         // const url_parts = window.location.href.split("?");
//     //         // if (url_parts.length === 2){
//     //         //     const id = url_parts.pop();
//     //         //     window.location.replace('#/login?saveContact='+id);
//     //         // } else {
//     //         //     window.location.replace('#/');
//     //         // }
//     //     // } 
        
//     // }

// });

export { auth, db, analytics, logEvent, perf, firebase }








