
<template>
  <div>
    <template v-if="deleteAccount">
      <DeleteUser :url="data.url" :user="user" @getCheckValue="setDelete" />
    </template>

    <template v-if="changePassword">
      <ChangePassword
        :url="data.url"
        :user="user"
        @goAccount="changePassword = false"
      />
    </template>
    <template v-else>
      <div
        class="background account"
        :style="
          'background-image: url(\'../images/amigo_logo_outline_bg.svg\'), ' +
          'linear-gradient(311deg, ' +
          color_dict.bg1 +
          ',' +
          color_dict.bg2 +
          ');'
        "
      >
        <HeaderAMIGO />

        <div class="page-body-2 without-footer account">
          <Header title="Account" :image="null" @back="goBack()" />

          <div class="transp-body data-fillin account">
            <!-- <h1 class="heading-4 white center smaller">{{ page_name }}</h1> -->

            <div class="form-div step1 margin-top">
              <template v-if="data.premium==true">
                <div class="field-collection">
                  <div class="div-block-79">
                    <div class="field-div vertical">
                      <div class="paragraph left marge-down">
                        Background color:
                      </div>
                      <div class="div-block-75">
                        <div class="div-block-77">
                          <input
                            type="color"
                            class="colors"
                            :value="color[0]"
                            ref="bg1"
                            @input="setColor(0, null, $refs.bg1.value)"
                          />
                        </div>
                        <div class="div-block-77">
                          <input
                            type="color"
                            class="colors"
                            :value="color[1]"
                            ref="bg2"
                            @input="setColor(1, null, $refs.bg2.value)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="div-block-80">
                      <a @click="resetBG()" class="link-block-10 w-inline-block"
                        ><img
                          src="images/undo-arrow.svg"
                          loading="lazy"
                          alt="AMIGO CONNECTED"
                          class="image-32"
                      /></a>
                    </div>
                  </div>
                </div>
                <div class="field-collection">
                  <div class="div-block-78">
                    <div class="tile_newsocial customize click">
                      <Icon
                        :social="'webshop'"
                        :colors_arr="[
                          color_dict.icon1,
                          color_dict.icon2,
                          color_dict.icon3,
                        ]"
                      />
                    </div>
                    <div class="field-div vertical">
                      <div class="paragraph left marge-down">Icon colors:</div>
                      <div class="div-block-75 _2">
                        <div class="div-block-77">
                          <input
                            type="color"
                            class="colors"
                            :value="color[2].icons[0]"
                            ref="icon1"
                            @input="setColor(2, 0, $refs.icon1.value)"
                          />
                        </div>
                        <div class="div-block-77">
                          <input
                            type="color"
                            class="colors"
                            :value="color[2].icons[1]"
                            ref="icon2"
                            @input="setColor(2, 1, $refs.icon2.value)"
                          />
                        </div>
                        <div class="div-block-77">
                          <input
                            type="color"
                            class="colors"
                            :value="color[2].icons[2]"
                            ref="icon3"
                            @input="setColor(2, 2, $refs.icon3.value)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="div-block-80">
                      <a
                        @click="resetIconColor()"
                        class="link-block-10 w-inline-block"
                        ><img
                          src="images/undo-arrow.svg"
                          loading="lazy"
                          alt="AMIGO CONNECTED"
                          class="image-32"
                      /></a>
                    </div>
                  </div>
                </div>
              </template>

              <div class="form-block w-form">
                <div class="field-collection">
                  <Url
                    :url="data.url"
                    :url_type="data.url_type"
                    :withPassword="true"
                    :email="data.email"
                    :uid="user.uid"
                    @url="setUrl"
                    @update="updateUrl"
                  />
                  <div v-if="update.email" class="direct-link hide-account" />
                </div>

                <div class="field-collection">
                  <Email
                    :email="data.email"
                    :user="user"
                    @email="setEmail"
                    @update="updateEmail"
                  />

                  <div class="sub-div">
                    <div class="field-div vertical">
                      <div class="account-column w-row">
                        <div class="left-account-column w-col w-col-6">
                          <div class="paragraph left">
                            {{ field_names.firstname }}
                          </div>
                          <input
                            ref="firstname"
                            :value="data.firstname"
                            :maxlength="input_length.firstname"
                            :placeholder="field_names.firstname"
                            required="true"
                            class="text-field w-input"
                            type="text"
                            autocorrect="off"
                            autocapitalize="off"
                          />
                        </div>
                        <div class="right-account-column w-col w-col-6">
                          <div class="paragraph left">
                            {{ field_names.lastname }}
                          </div>
                          <input
                            ref="lastname"
                            :value="data.lastname"
                            :maxlength="input_length.lastname"
                            :placeholder="field_names.lastname"
                            required="true"
                            class="text-field w-input"
                            type="text"
                            autocorrect="off"
                            autocapitalize="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div id="form-footer">
                      <div class="button-div">
                        <a
                          @click="updateVariables()"
                          class="submit-button w-button"
                          >{{ btn_update }}</a
                        >
                      </div>

                      <div>
                        <div v-if="error !== ''" class="error-message">
                          {{ error }}
                        </div>
                        <div
                          v-if="(error === '') & (success !== '')"
                          class="success-message"
                        >
                          {{ success }}
                        </div>
                      </div>

                      <a
                        @click="setPassword(true)"
                        class="account-button w-inline-block"
                      >
                        <div class="arrow-txt adjustm">
                          {{ btn_changePass }}
                        </div>
                        <img
                          src="images/right-arrow.png"
                          loading="lazy"
                          class="arrow"
                        />
                      </a>
                      <a
                        @click="setDelete(true)"
                        class="account-button w-inline-block"
                      >
                        <div class="arrow-txt adjustm">{{ btn_delete }}</div>
                        <img
                          src="images/right-arrow.png"
                          loading="lazy"
                          class="arrow"
                        />
                      </a>
                    </div>
                    <div v-if="update.email" class="direct-link hide-account" />
                  </div>

                  <div v-if="update.url" class="direct-link hide-account" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import json from "../General/GlobalValues.json";
import methods from "../General/Methods/General.js";

import ChangePassword from "./ChangePassword.vue";
import DeleteUser from "./DeleteAccount.vue";

import Email from "../General/Elements/InputFields/Email.vue";
import Url from "../General/Elements/InputFields/setUrl.vue";

import db_user from "../General/DB/user/main.js";

import HeaderAMIGO from "../General/Elements/Headers/Header1.vue";
import Header from "../General/Elements/Headers/TitleWithBack.vue";

import Icon from "../General/Elements/Apps/AppIcon.vue";

export default {
  components: {
    ChangePassword,
    DeleteUser,
    Email,
    Url,
    HeaderAMIGO,
    Header,
    Icon,
  },

  props: ["input_data", "user", "input_color"],
  data() {
    return {
      field_names: json["field_names"],
      input_length: json["input_length"],

      page_name: "Account",
      back_btn: "Profile",

      btn_update: "Update",

      btn_changePass: "Change Password",
      btn_delete: "Delete Account",

      changePassword: false,
      deleteAccount: false,

      data: {
        firstname: "",
        lastname: "",
        url: "",
        url_type: "",
        email: "",
        premium: false,
      },

      error: "",
      success: "",

      update: {
        url: false,
        email: false,
      },

      color: null,
      color_dict: {
        bg1: null,
        bg2: null,
        icon1: null,
        icon2: null,
        icon3: null,
      },

      initialcolor: null,
    };
  },

  async created() {
    this.data = this.input_data;
    this.initialcolor = methods.deepcopy(this.input_color);
    this.initColors();
  },

  methods: {
    initColors() {

      this.color = methods.deepcopy(this.initialcolor);

      this.setBackgroundColor(0, this.color[0]);
      this.setBackgroundColor(1, this.color[1]);

      this.setIconColor(0, this.color[2].icons[0]);
      this.setIconColor(1, this.color[2].icons[1]);
      this.setIconColor(2, this.color[2].icons[2]);
    },
    async updateVariables() {
      const data = await this.setVariables();
      if (methods.objectIsEmpty(data)) {
        this.success = "All your personal data is up-to-date";
      } else {
        if (this.input_data.offline) {
          await db_user.data
            .updateData(this.user.uid, data, "public")
            .catch(() => {
              this.error = "Sorry there is something wrong!";
            });
        } else {
          await db_user.vData.updateData(this.user.uid, data).catch(() => {
            this.error = "Sorry there is something wrong!";
          });
        }

        this.success = "You successfully updated your profile!";
      }
    },

    async setColor(index1, index2, value) {
      if (index2 == null) {
        this.setBackgroundColor(index1, value);
        this.color[index1] = value;
      } else {
        this.setIconColor(index2, value);
        this.color[index1].icons[index2] = value;
      }
      await db_user.vData.updateData(this.user.uid, { color: this.color });
      this.$emit("setColor", this.color);
    },

    setBackgroundColor(index, value) {
      if (index == 0) {
        this.color_dict.bg1 = value;
      } else if (index == 1) {
        this.color_dict.bg2 = value;
      }
    },

    setIconColor(index, value) {
      if (index == 0) {
        this.color_dict.icon1 = value;
      } else if (index == 1) {
        this.color_dict.icon2 = value;
      } else if (index == 2) {
        this.color_dict.icon3 = value;
      }
    },

    setVariables() {
      var x = {};
      if (this.data.firstname !== this.$refs.firstname.value) {
        x["firstname"] = this.$refs.firstname.value;
        this.data.firstname = this.$refs.firstname.value;
      }
      if (this.data.lastname !== this.$refs.lastname.value) {
        x["lastname"] = this.$refs.lastname.value;
        this.data.lastname = this.$refs.lastname.value;
      }

      return x;
    },

    setDelete(value) {
      this.deleteAccount = value;
    },

    setPassword(value) {
      this.changePassword = value;
    },

    goBack() {
      this.$emit("back", this.data);
    },

    async setUrl(url) {
      this.data.url = url;
      this.update.url = false;
      await db_user.vData.updateData(this.user.uid, { url: url });
      this.$emit("data", this.data);
    },

    async setEmail(email) {
      this.data.email = email;
      this.update.email = false;
      this.$emit("data", this.data);
    },

    updateUrl(value) {
      this.update.url = value;
    },
    updateEmail(value) {
      this.update.email = value;
    },

    async resetBG() {
      this.initColors();
      await db_user.vData.updateData(this.user.uid, { color: this.color });
      this.$emit("setColor", this.color);
    },

    async resetIconColor() {
      this.initColors();
      await db_user.vData.updateData(this.user.uid, { color: this.color });
      this.$emit("setColor", this.color);
    },
  },
};
</script>
