
<template>
  <div>
    <template v-if="checkDelete">
      <Check :list="list" :idx="id" @deleteCheck="setDelete" />
    </template>
    <div
      class="background _100vh"
      :style="
        'background-image: url(\'../images/amigo_logo_outline_bg.svg\'), ' +
        'linear-gradient(311deg, ' +
        color[0] +
        ',' +
        color[1] +
        ');'
      "
    >
      <HeaderAMIGO />

      <template v-if="setVariables">
        <div class="page-body center contacts">
          <Header
            title="Your saved contacts"
            image="images/shining.png"
            @back="goToProfile()"
          />

          <div class="saved-contacts-div">
            <div class="scroll-div contacts">
              <draggable
                tag="ul"
                :list="list"
                class="list w-list-unstyled"
                handle=".handle"
              >
                <li class="list-item" v-for="(element, idx) in list" :key="idx">
                  <div class="columns-7 w-row">
                    <div class="center w-col w-col-2">
                      <a class="link-block-6 w-inline-block handle">
                        <img
                          src="images/drag.png"
                          loading="lazy"
                          class="options-img"
                        />
                      </a>
                    </div>
                    <div class="center w-col w-col-5">
                      <a
                        @click="goLink(element.uid)"
                        class="name-link w-inline-block"
                      >
                        <div
                          class="max-size"
                          :style="{
                            'background-image':
                              'url(' + setPicture(element.uid) + ')',
                            'background-position': '50% 50%',
                            'background-size': 'cover',
                          }"
                        />
                        <div class="purple name">
                          {{ setName(element.uid) }}
                        </div>
                      </a>
                    </div>
                    <div class="center non-mobile w-col w-col-3">
                      <a
                        @click="goLink(element.uid)"
                        class="name-link w-inline-block"
                      >
                        <div class="purple">visit profile</div>
                      </a>
                    </div>
                    <div class="center w-col w-col-2">
                      <a @click="removeAt(idx)" class="w-inline-block">
                        <img
                          src="images/close-transparant-white.png"
                          loading="lazy"
                          height=""
                          srcset="
                            images/close-transparant-white-p-500.png 500w,
                            images/close-transparant-white.png       512w
                          "
                          sizes="(max-width: 479px) 17px, (max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
                          alt=""
                          class="close-img small"
                      /></a>
                    </div>
                  </div>
                </li>
              </draggable>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import draggable from "vuedraggable";

import Check from "./Check.vue";

import db_user from "../General/DB/user/main.js";

import HeaderAMIGO from "../General/Elements/Headers/HeaderAMIGO.vue";

import Header from "../General/Elements/Headers/TitleWithBack.vue";

export default {
  // order: 5,
  components: {
    draggable,
    Check,
    HeaderAMIGO,
    Header,
  },
  props: ["user", "color"],
  data() {
    return {
      checkDelete: false,
      dragging: false,
      showMenu: false,
      setVariables: false,

      score: 0,
      id: -1,

      list: [],
      userData: [],
    };
  },

  watch: {
    list: {
      handler() {
        if (this.score !== 0) {
          db_user.contacts.updateContacts(this.user.uid, this.list);
        }
      },
    },
    savedLink: {
      handler() {
        if (this.savedLink !== "") this.$router.push(this.savedLink);
      },
    },
  },

  async created() {
    if (this.user) {
      await this.fetchGridsData();
    } else {
      this.$router.push("/");
      this.$router.go();
    }
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },

  methods: {
    async fetchGridsData() {
      this.list = await db_user.contacts.getContacts(this.user.uid);
      this.score = await db_user.contacts.countContacts(this.list);
      if (this.score > 0) {
        await this.setData();
      }

      this.setVariables = true;
    },

    async setData() {
      for (var i = 0; i < this.list.length; i++) {
        if (
          (await db_user.contacts.getContactInfo(this.list[i].uid)) !== null
        ) {
          var contact = await db_user.contacts.getContactInfo(this.list[i].uid);

          this.userData.push(contact);
        }
        // this.list[i].name = this.userData[i].name;
      }
    },

    setMenu() {
      this.$emit("goMenu", true);
    },

    removeAt(idx) {
      this.id = idx;
      this.checkDelete = true;
    },
    setDelete(value) {
      this.checkDelete = value;
    },

    goLink(element) {
      window.location.replace(
        "/" + this.userData.find((x) => x.uid === element).url
      );
    },

    setName(element) {
      return this.userData.find((x) => x.uid === element).nickname;
    },

    setPicture(element) {
      return this.userData.find((x) => x.uid === element).image;
    },

    goToProfile() {
      this.$emit("goBack", true);
    },
  },
};
</script>
