<template>
  <div>
    <template v-if="!hidePopUp">
      <WaitPage />
    </template>

    <template v-else-if="showMenu">
      <template v-if="loggedIn">
        <AccountMenu
          :url="userData.url"
          :showMenu="showMenu"
          :onConfig="toConfigPage"
          :onContact="toContacts"
          :color="userData.color"
          @showMenu="getMenu"
          @goAccount="setAccountPage"
          @goProfile="goProfile"
          @goQR="goQRPage"
          @goContacts="goToContacts"
          @setOff="setOff"
        />
      </template>
      <template v-else>
        <PublicMenu
          :showMenu="showMenu"
          :color="userData.color"
          @showMenu="getMenu"
        />
      </template>
    </template>
    <template v-else-if="goToRemember">
      <Remember
        :name="userData.nickname"
        :uid="userData.uid"
        :score="userData.score"
        :user="user"
        @goBack="backFromRem"
        @score="setScore"
      />
    </template>
    <template v-else-if="goQR">
      <QR :url="userData.url" :color="userData.color" @goBack="backFromQR" />
    </template>
    <template v-else-if="toContacts">
      <Contacts
        :user="user"
        :color="userData.color"
        @goBack="backFromContacts"
        @goMenu="getMenu"
      />
    </template>
    <template v-else-if="loggedIn & toConfigPage & dataLoaded">
      <template v-if="goAccount">
        <AccountPage
          :input_data="accountData"
          :user="user"
          :input_color="userData.color"
          @data="setData"
          @back="backAccount"
          @setColor="setColor"
        />
      </template>
      <template v-else>
        <ConfigPage
          :user_info="userData"
          :social_data="profileData"
          :user="user"
          @userData="setUserData"
          @profileData="setProfileData"
          @logout="logout"
          @menu="setMenu"
          @hidePopUp="setPopUp"
          @setPData="setPData"
        />
      </template>
    </template>

    <template v-else-if="toPublicPage & dataLoaded">
      <Public
        :profileData="profileData"
        :userData="userData"
        :numberOfItems="oItemsLength"
        :user="user"
        @setMenu="setMenu"
        @hidePopUp="setPopUp"
        @goRemember="setRemember"
      />
    </template>
  </div>
</template>


<script>
import { auth } from "../firebase";

import AccountMenu from "../Menu/Account.vue";
import PublicMenu from "../Menu/LoggedOut.vue";

import Contacts from "../Contacts/Overview.vue";
import Remember from "../Contacts/Remember.vue";
import WaitPage from "../General/WaitPage.vue";
import ConfigPage from "../Configuration/Main.vue";
import Public from "../PublicProfile/Main.vue";
import AccountPage from "../Account/Main.vue";
import QR from "../Account/QR.vue";

import methods from "../General/Methods/General.js";
import db_user from "../General/DB/user/main.js";
import db_url from "../General/DB/url.js";
import db_auth from "../General/DB/auth.js";

import { analytics, logEvent } from "../firebase/index";

export default {
  components: {
    AccountMenu,
    AccountPage,
    PublicMenu,
    Remember,
    WaitPage,
    ConfigPage,
    Public,
    QR,
    Contacts,
  },
  data: () => {
    return {
      userData: {
        nickname: "",
        firstname: "",
        lastname: "",
        bio: "",
        image: "",
        score: 0,
        url: "",
        noProfile: false,
        numberOfApps: 9,
        view: null,
      },

      profileData: {
        borderColor: "",
        items: [...Array(0)].map(() => null),
        redirect: false,
      },

      page: {
        uid: "",
        type: "",
        code: "",
        url: "",
      },

      accountData: {
        firstname: "",
        lastname: "",
        url: "",
        email: "",
      },

      toConfigPage: false,
      toPublicPage: false,
      goToRemember: false,
      toContacts: false,

      dataLoaded: false,
      hidePopUp: false,
      timerIsDone: false,
      showMenu: false,
      loggedIn: false,

      pageExists: true,
      goAccount: false,
      goQR: false,

      user: null,
    };
  },
  computed: {
    oItems() {
      return this.profileData.items.filter((a) => a);
    },
    oItemsLength() {
      return this.oItems.length;
    },
  },

  async created() {
    setTimeout(this.minPopUp, 3100, true);
    var url = this.$route.fullPath.split("/").pop();
    var initial_url = null;
    if (url.toLowerCase().includes("qr")) {
      url = url.split("?")[0];
      initial_url = window.location.href;
      this.$router.replace(url);
    } else {
      initial_url = window.location.href;
    }

    await auth.onAuthStateChanged(async (user) => {
      
      // this.dataLoaded = false;
      this.user = user;
      await this.setPage(url, user);

      if (url !== "" && (await this.registeredProduct())) {
        if (this.$route.fullPath !== "/activation?" + url) {
          this.$router.push("/activation?" + url);
        }
        return;
      } else if (this.page !== null) {
        await this.fetchGridsData(initial_url);
        if (await this.toConfigPage) {
          this.accountData.email = user.email;
        }
      } else {
        this.userData = methods.setEmptyAccount();
      }

      this.setColors();

      logEvent(analytics, "views_per_account", {
        account: this.userData.url,
      });

      this.dataLoaded = true;

      this.setPopUp(true)
       
    });
  },

  methods: {
    minPopUp(boolean){
      this.timerIsDone = boolean;
      this.hidePopUp = boolean;
    },

    async registeredProduct() {
      /* check if if url is registered */

      if (this.page !== null) {
        if (
          (await methods.viaProduct(this.page.url)) &&
          (await methods.isReserved(this.page.uid))
        ) {
          this.page.url = this.page.url.split("=")[1]; // set url correct
          return true;
        } else if (
          /*only first products && */ await methods.isReserved(this.page.uid)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    async setPage(url, user) {
      var stay = false;

      if (user) {
        this.loggedIn = true;

        if (user.uid !== undefined) {
          this.userData.url = await db_user.vData.getUrl(user.uid);
        }

        if (url === "") {
          history.pushState(
            {},
            null,
            this.$route.path + "#/" + this.userData.url
          );
          url = this.userData.url;
          this.page = await db_url.getPrivate(this.userData.url);
          this.toConfigPage = true;
        } else if (this.userData.url === url) {
          this.page = await db_url.getPrivate(this.userData.url);
          this.toConfigPage = true;
        } else {
          this.toPublicPage = true;
        }
        stay = true;
      } else {
        this.loggedIn = false;

        if (url === "") {
          this.$router.push("/login");
          this.$router.go();
          return;
        } else {
          stay = true;
          this.toPublicPage = true;
        }
      }

      if (stay) {
        const publicData = await db_url.getPublic(url);
        if (publicData !== null) {
          this.page = Object.assign({}, this.page, await db_url.getPublic(url));
        } else {
          this.page = null;
        }
      }
    },

    async fetchGridsData(initial_url) {
      this.userData = await db_user.vData.getData(this.page.uid);
      this.userData.initial_url = initial_url;

      await this.setExtraDataFields();

      if (this.userData.offline) {
        this.userData = Object.assign(
          {},
          this.userData,
          await db_user.data.getPrivateUserData(this.page.uid)
        );
      }

      var socialData = await db_user.vSocials.getSocials(this.page.uid);
      await this.initialize(socialData.data);

      this.profileData.redirect = socialData.redirect;
    },

    async initialize(sItems) {
      var items = [...Array(this.userData.numberOfApps)].map(() => null);
      if (sItems[0] === null) {
        this.profileData.items = items;
        return;
      }

      items = await [...Array(this.userData.numberOfApps)].map((el, i) => {
        let item = sItems.find((item) => item.position === i);
        if (item) {
          return item;
        }
        return null;
      });

      this.profileData.items = await items;
    },

    async setExtraDataFields() {
      if (this.userData.numberOfApps === undefined) {
        this.userData.numberOfApps = 9;
      }
    },

    setMenu(value) {
      if (!this.showMenu) {
        this.showMenu = value;
      }
    },

    getMenu(value) {
      this.showMenu = value;
    },

    setOff() {
      this.toContacts = false;
    },

    goProfile() {
      window.location.replace("/");
    },

    getTarget(title) {
      if (title === "mail" || title === "phone") {
        return "";
      }
      return "_blank";
    },

    backFromRem(value) {
      this.userData.score += value;
      this.goToRemember = false;
    },

    async logout(value) {
      if (value) {
        await db_auth.logout();
      }
    },

    setUserData(value) {
      this.userData = value;
    },

    setProfileData(value) {
      this.profileData = value;
    },

    setPopUp(value) {
      if (this.timerIsDone) {
        this.hidePopUp = value;
      }
        
    },

    setAccountPage(value) {
      this.accountData.firstname = this.userData.firstname;
      this.accountData.lastname = this.userData.lastname;
      this.accountData.url = this.userData.url;
      this.accountData.url_type = this.page.type;
      this.accountData.offline = this.userData.offline;
      this.accountData.premium = this.userData.premium;

      this.goAccount = value;
      this.showMenu = !value;
    },

    setData(data) {
      this.userData.firstname = data.firstname;
      this.userData.lastname = data.lastname;
      this.userData.url = data.url;
      this.userData.email = data.email;
    },

    backAccount(data) {
      this.setData(data);
      this.goAccount = false;
    },

    goQRPage(value) {
      this.goQR = value;
      this.showMenu = !value;
    },

    backFromQR(value) {
      this.goQR = !value;
      this.showMenu = value;
    },

    goToContacts(value) {
      this.toContacts = value;
      this.showMenu = !value;
    },

    backFromContacts(value) {
      this.toContacts = !value;
    },

    setRemember(value) {
      this.goToRemember = value;
    },

    async setPData(value) {
      this.userData = await Object.assign({}, this.userData, value.d);

      await this.initialize(value.s.data);

      this.profileData.redirect = value.s.redirect;
    },

    setScore(score) {
      this.userData.score = score;
    },

    setColor(colors) {
      this.userData.color = colors;
    },

    setColors() {
      if (this.userData.color === undefined) {
        this.userData.color = [
          "#321fe2",
          "#bf1fd6",
          {
            icons: ["#321fe2", "#bf1fd6", "#ffffff"],
          },
        ];
      }

      if (this.userData.color[0] === undefined) {
        this.userData.color = [
          "#321fe2",
          this.userData.color[1],
          this.userData.color[2],
        ];
      }

      if (this.userData.color[1] === undefined) {
        this.userData.color = [
          this.userData.color[0],
          "#bf1fd6",
          this.userData.color[2],
        ];
      }

      if (this.userData.color[2] === undefined) {
        this.userData.color = [
          this.userData.color[0],
          this.userData.color[1],
          {
            icons: ["#321fe2", "#bf1fd6", "#ffffff"],
          },
        ];
      }
    },
  },
};
</script>