
import json from '../../GlobalValues.json'
import {db} from '../../../firebase'


export default {

    //Socials
    async setSocials(uid, data) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(json['docs'].socials).set({
            data: data,
            redirect: false,
        })
        .catch((err) => {
            throw err.message;
        })
    },

    async deleteSocials(uid) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(json['docs'].socials).delete()
        .catch((err) => {
            throw err.message;
        })

        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(json['docs'].public).delete()
        .catch((err) => {
            throw err.message;
        })
    },

    async updateSocials(uid, socials) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(json['docs'].socials).update({
            data: socials
        })
        .catch((err) => {
            throw "Error: " + err.message;
        })
    },

    async getSocials(uid) {
        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(json['docs'].socials).get()
        .then(doc => {
        
            return doc.data();
        })
        .catch(() => {
            throw "It seems that you have no socials"
        })
    },

    async updateRedirect(uid, value) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(json['docs'].socials).update({
            redirect: value
        })
        .catch((err) => {
            throw "Error: " + err.message;
        })
    },

    async resetSocials(uid) {
       
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].socials).doc(json['docs'].socials).set({
            data: [],
            redirect: false
        })
        .catch((err) => {
            throw "Error: " + err.message;
        })
    },

}