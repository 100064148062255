import {auth, firebase} from '../../firebase'

export default {

    async login(email,password) {
        return await auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            return userCredential.user;
        })
        .catch((err) => {
            throw err.message;
        });
    },

    async logout() {
        auth.signOut()
        .catch(() => {
            throw "Not succesfully logout!"
        });
    },

    async createUser(email,password) {
        return await auth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
            return userCredential.user;
        })
        .catch((err) => {
            throw err.message
        });
    },

   async deleteUser(user) {
       await user.delete()                       
       .catch((error) => {
            throw "Error: user is not deleted! ", error;
       });   
   },

   async mailAvailable(email) {
        return await auth.fetchSignInMethodsForEmail(email)
        .then((signInMethods) => {
            if (signInMethods.length) {
                //user does already exist
                return false;
            } else {
                //user doesn't exist
                return true;
            }
        })
        .catch((error) => { 
            throw error.message;
        });   
    },

    async userState() {
        return await auth.onAuthStateChanged(async (user) => {
            return user;
        })
        .catch((err) => {
            throw err.message;
        })
    },

    async checkState(router) {
        var unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                router.push('/')
            }
        });

        await unsubscribe();
    },

    

    async resetEmail(email) {
        
        return auth.sendPasswordResetEmail(email)
        .then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    },
    
    async resetPassword(code, password) {
        return auth.confirmPasswordReset(code, password)
        .then(() => {
            
            return true;
        })
        .catch(() => {
            return false;
        });
    },

    async getCredential(email, password) {
   
        return await auth.signInWithEmailAndPassword(email, password)
        .then(() => {
            var credential = firebase.auth.EmailAuthProvider.credential(email, password);
 
            return credential
            
        })
        .catch((err) => {
            const message1 = 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
            if (err.message == message1) {
                throw 'Access to this account has been temporarily disabled due to many failed login attempts. You can try again later.'
            }

            throw err.message;
        })
    },

    async reauthenticate(user, password) {
   
        var credential = await this.getCredential(user.email, password)
        .catch((err) => {
            throw err;
        });


        var isAuthenticated = await user.reauthenticateWithCredential(credential)
        .catch((err) => {
           
            throw "Sorry " + err.message;  
        })
        
        if (isAuthenticated) {
            return true;
        }
    },

    async changePassword(user, oldPassword, newPassword) {
        
        var credential = await this.getCredential(user.email, oldPassword)
        .catch((err) => {
            throw err.message;
        });

        if (credential !== null) {
            var isUpdated = await user.updatePassword(newPassword)
            .then(()=>{
                return true;
            })
            .catch((err) => {
                throw err.message;
            })

            if (isUpdated) {
                return true;
            } else {
                throw "Something went wrong!"
            }

        
            
        } else {
            
            return false;
        }
        
    },

    async updateEmail(user, newEmail,password) {
     
        await this.reauthenticate(user,password)
        .catch((err) => {
            throw err;
        })
        user.updateEmail(newEmail)
    }



  
}
   


   