<template>
  <div>
    <template v-if="isLocal">
      <LocalSocial :data="data" :color="userData.color" @back="localBack" />
    </template>

    <div
      :style="
        'background-image: url(\'../images/amigo_logo_outline_bg.svg\'), linear-gradient(311deg, ' +
        userData.color[0] +
        ',' +
        userData.color[1] +
        ');'
      "
      class="background"
      v-if="!profileData.redirect"
    >
      <Header
        :editorMode="null"
        :showDescription="false"
        menu_icon="images/option-white.png"
        @menu="setMenu"
        @editorMode="doNothing"
      />

      <template v-if="view == 'default'">
        <DefaultTemplate
          :profileData="profileData"
          :userData="userData"
          :numberOfItems="numberOfItems"
          :uid="uid"
          :color="userData.color"
          @goRemember="goRemember"
          @isLocal="setLocal"
          @alert="alert=true"
        />
      </template>
      <template v-else-if="view == 'hospitality'">
        <HospitalityTemplate
          :profileData="profileData"
          :userData="userData"
          :numberOfItems="numberOfItems"
          :uid="uid"
          :color="userData.color"
          @goRemember="goRemember"
          @isLocal="setLocal"
          @alert="alert=true"
        />
      </template>
    </div>

    <template v-if="alert">
      <Alert
      :link="'https://amigo.bio/' + userData.url"
      title="Sorry.."
      text="Apple blocks this function on Chrome 😒. You can use this function in your standard Apple browser: Safari"
      button="Copy link"
      @close="alert=false"/>

    </template>
  </div>
</template>


<script>
import ProfilePicture from "../General/Elements/ProfilePicture.vue";
import LeftHeader from "../General/Elements/Headers/LeftHeader.vue";
import LocalSocial from "../General/Elements/LocalSocial.vue";
import { local_socials } from "../General/Elements/Apps/social_media.js";

import DefaultTemplate from "./Default.vue";
import HospitalityTemplate from "./Hospitality.vue";

import Header from "../General/Elements/Headers/EditorModeHeader.vue";
import Alert from "../General/Elements/PopUps/Title_Text_CopyButton.vue";

export default {
  components: {
    ProfilePicture,
    LeftHeader,
    LocalSocial,
    DefaultTemplate,
    HospitalityTemplate,
    Header,
    Alert,
  },
  props: ["userData", "profileData", "numberOfItems", "user"],
  data: () => {
    return {
      isLocal: false,
      data: null,
      uid: null,
      view: "default",

      alert: false,
    };
  },

  async created() {
    
    if (this.user != null) {
      this.uid = this.user.uid;
    }
    if (this.userData.view != undefined) {
      this.view = this.userData.view;
    }

    var currentRoute = this.$router.currentRoute.fullPath.split("/").pop();

    if (this.userData.url !== currentRoute) {
      this.$router.push("/" + this.userData.url);
    }
    if (this.profileData.redirect & (this.profileData.items[0] !== undefined)) {
      if (!(await this.isExternalSocial(this.profileData.items[0]))) {
        await this.setLocal(this.profileData.items[0]);
      } else {
        /* redirect to social */
        window.location.assign(this.profileData.items[0].data.field1);
      }
    } else if (this.profileData.items[0] === null) {
      this.$emit("hidePopUp", true);
    }
  },

  methods: {
    getLink(link, index) {
      if (index === this.numberOfItems - 1) {
        this.$emit("hidePopUp", true);
      }
      return link;
    },

    getTarget(title) {
      if (title === "mail" || title === "phone") {
        return "";
      }
      return "_blank";
    },

    setMenu() {
      this.$emit("setMenu", true);
    },

    localBack(value) {
      this.isLocal = !value;
    },
    setLocal(item) {
      this.data = item;
      this.isLocal = true;
    },
    isExternalSocial(item) {
      if (local_socials.includes(item.social)) {
        return false;
      }

      return true;
    },
    setPopUp(index) {
      if (index === this.numberOfItems - 1) {
        this.$emit("hidePopUp", true);
      }
      return true;
    },

    goRemember(value) {
      this.$emit("goRemember", value);
    },

    doNothing() {
      // method that runs in case something goes wrong, however doens't do anything
    },
  },
};
</script>