const general = [
  { name: 'URL', social: 'url', options: [['link']] },
  { name: 'Webshop', social: 'webshop', options: [['link']] },
  { name: 'Image', social: 'image', options: [['link']] },
  { name: 'Phone', social: 'phone', options: [['number']] },
  { name: 'Email', social: 'mail', options: [['mail']] },
  { name: 'Maps', social: 'maps', options: [['adress']] },
  { name: 'Company', social: 'company', options: [['company'], ['job role'], ['location'], ['phone'], ['email']] },
  { name: 'Login', social: 'login', options: [['link']] },
  { name: 'Register', social: 'register', options: [['link']] },
  { name: 'File', social: 'file', options: [['link']] },

  { name: 'BOB', social: 'bob', options: [['link']] },
  { name: 'Twitter', social: 'twitter', options: [['username', 'link']] },
  { name: 'Instagram', social: 'instagram', options: [['username', 'link']] },
  { name: 'Linkedin', social: 'linkedin', options: [['link']] },
  { name: 'TikTok', social: 'tiktok', options: [['username', 'link']] },
  { name: 'Snapchat', social: 'snapchat', options: [['username', 'link']] },
  { name: 'Facebook', social: 'facebook', options: [['username', 'link']] },
  { name: 'Pinterest', social: 'pinterest', options: [['link', 'username']] },
  { name: 'YouTube', social: 'youtube', options: [['link']] },
  { name: 'Spotify', social: 'spotify', options: [['link']] },
  { name: 'SoundCloud', social: 'soundcloud', options: [['username', 'link']] },
  { name: 'Music', social: 'applemusic', options: [['link']] },
  { name: 'Deezer', social: 'deezer', options: [['link']] },
  { name: 'Tidal', social: 'tidal', options: [['link']] },

  { name: 'Forms', social: 'google-forms', options: [['link']] },
]

const standard_acc = [
  { name: 'Vinted', social: 'vinted', options: [['link']] },
  { name: 'Speakrs', social: 'speakrs', options: [['link']] },
  { name: 'Polarsteps', social: 'polarsteps', options: [['link']] },

  { name: 'Telegram', social: 'telegram', options: [['link']] },
  { name: 'WeChat', social: 'wechat', options: [['username']] },
  { name: 'Skype', social: 'skype', options: [['link']] },

  { name: 'Twitch', social: 'twitch', options: [['username', 'link']] },
  { name: 'Amazon', social: 'amazon', options: [['link']] },
  { name: 'Bol.com', social: 'bol-com', options: [['link']] },

  { name: 'GitHub', social: 'github', options: [['username', 'link']] },
  { name: 'Tikkie', social: 'tikkie', options: [['link']] },

  { name: 'Payment', social: 'ing', options: [['link']] },
  { name: 'Payment', social: 'rabobank', options: [['link']] },
  { name: 'Payment', social: 'snsbank', options: [['link']] },
  { name: 'Payment', social: 'bunq', options: [['link']] },

  { name: 'Blinkist', social: 'blinkist', options: [['link']] },

  { name: 'OnlyFans', social: 'onlyfans', options: [['username', 'link']] },

  { name: 'Datepicker', social: 'datumprikker', options: [['link']] },
  { name: 'Behance', social: 'behance', options: [['username', 'link']] },

  { name: 'Patreon', social: 'patreon', options: [['username', 'link']] },
  { name: 'Discord', social: 'discord', options: [['link']] },
  { name: 'Gamertag', social: 'xbox', options: [['gamertag']] },
  { name: 'Gamertag', social: 'playstation', options: [['gamertag']] },
]



const premium_socials = [
  { name: 'Shoes', social: 'shoes', options: [['link']] },
  { name: 'Shirt', social: 'shirt', options: [['link']] },
  { name: 'Trousers', social: 'trousers', options: [['link']] },

  { name: 'Zuyd', social: 'zuyd', options: [['link']] },
  { name: 'Week van Respect', social: 'weekvanrespect', options: [['link']] },
  { name: 'VWO', social: 'vwo', options: [['link']] },
  { name: 'Studie Xtra', social: 'studie_xtra', options: [['link']] },
  { name: 'SSP', social: 'ssp', options: [['link']] },
  { name: 'Spreekuur', social: 'spreekuur', options: [['link']] },
  { name: 'Pestweb', social: 'pestweb', options: [['link']] },
  { name: 'Mavo', social: 'mavo', options: [['link']] },
  { name: 'Lesrooster', social: 'lesrooster', options: [['link']] },
  { name: 'KrapNL', social: 'krapnl', options: [['link']] },
  { name: 'Koning Willem 1', social: 'koningwillemIcollege', options: [['link']] },
  { name: 'Ideeen Box', social: 'ideeenbox', options: [['link']] },
  { name: 'ICT', social: 'ict', options: [['link']] },
  { name: 'Huis73', social: 'huis73', options: [['link']] },
  { name: 'Havo', social: 'havo', options: [['link']] },
  { name: 'Halt', social: 'halt', options: [['link']] },
  { name: 'Gevonden Voorwerpen', social: 'gevonden_voorwerpen', options: [['link']] },
  { name: 'Feedback', social: 'feedback', options: [['link']] },
  { name: 'Een Tegen Eenzaamheid', social: 'eentegeneenzaamheid', options: [['link']] },
  { name: 'Deadline', social: 'deadline', options: [['link']] },
  { name: 'Challenge', social: 'challenge', options: [['link']] },
  { name: 'Brugklas', social: 'brugklas', options: [['link']] },
  { name: 'Bijbaan', social: 'bijbaan', options: [['link']] },
  { name: 'Aanvraag Buddy', social: 'aanvraag_buddy', options: [['link']] },
  { name: 'Aanvraag Anoniem', social: 'aanvraag_anoniem', options: [['link']] },


]


const hospitality = [
  { name: 'Tripadvisor', social: 'tripadvisor', options: [['link']] },
  { name: 'Google Reviews', social: 'googlereviews', options: [['link']] },
  { name: 'Yelp', social: 'yelp', options: [['link']] },
  { name: 'Gevonden Voorwerpen', social: 'gevonden_voorwerpen', options: [['link']] },
  { name: 'Thuisbezorgd', social: 'thuisbezorgd', options: [['link']] },
  { name: 'Uber Eats', social: 'ubereats', options: [['link']] },
  { name: 'Deliveroo', social: 'deliveroo', options: [['link']] },
  { name: 'Untappd', social: 'untappd', options: [['link']] },

  { name: 'WiFi', social: 'wifi', options: [['WiFi name'], ['password']] },
  { name: 'Meatloverz', social: 'meatloverz', options: [['link']] },
  { name: 'Favourites', social: 'favourites', options: [['link']] },
  { name: 'Gluten Free', social: 'glutenfree', options: [['link']] },
  { name: 'Halal', social: 'halal', options: [['link']] },
  { name: 'Vegan', social: 'vegan', options: [['link']] },

  { name: 'Originates', social: 'whereitcomesfrom', options: [['link']] },
  { name: 'Area Map', social: 'areamap', options: [['link']] },
  { name: 'Reservation', social: 'reservation', options: [['link']] },
  { name: 'Park', social: 'park', options: [['link']] },
  { name: 'Public transport', social: 'publictransport', options: [['link']] },
  { name: 'Business hours', social: 'businesshours', options: [['link']] },
  { name: 'Ticket', social: 'ticket', options: [['link']] },
  { name: 'Gift', social: 'gift', options: [['link']] },
  { name: 'Deal', social: 'deal', options: [['link']] },
  { name: 'Food', social: 'food', options: [['link']] },
  { name: 'Event', social: 'event', options: [['link']] },
  { name: 'Drinks', social: 'drinks', options: [['link']] },
  { name: 'Text', social: 'textbox', options: [['title'], ['description'], ['image']] },
  { name: 'Jobs', social: 'jobs', options: [['link']] },

  { name: 'Car', social: 'car', options: [['link']] },
  { name: 'Covid-19', social: 'covid', options: [['link']] },
  { name: 'Calendar', social: 'calendar', options: [['link']] },
  { name: 'Feedback', social: 'feedback', options: [['link']] },

  { name: 'Menu', social: 'menu', options: [['link']] },
  { name: 'Menu Breakfast', social: 'menu_breakfast', options: [['link']] },
  { name: 'Menu Lunch', social: 'menu_lunch', options: [['link']] },
  { name: 'Menu Dinner', social: 'menu_dinner', options: [['link']] },
  { name: 'Menu Child', social: 'menu_child', options: [['link']] },
  { name: 'Menu Dessert', social: 'menu_dessert', options: [['link']] },

  { name: 'Fietsroute', social: 'fietsroute', options: [['link']] },
  { name: 'Scover', social: 'scover', options: [['link']] },

  { name: 'Beer', social: 'beer', options: [['link']] },
  { name: 'Book', social: 'book', options: [['link']] },
]




const username_addons =
{
  twitter: '/',
  instagram: '/',
  linkedin: '/in/',
  behance: '/',
  tiktok: '/@',
  snapchat: '/add/',
  facebook: '/',
  youtube: '/',
  github: '/',
  pinterest: '/',
  twitch: '/',
  onlyfans: '/',
  soundcloud: '/',
  patreon: '/'
}



const local_socials =
  [
    'xbox',
    'playstation',
    'company',
    'wifi'
  ]


const maps_var = ['adress', 'country', 'zip code', 'city']




export { standard_acc, general, premium_socials, hospitality, username_addons, local_socials, maps_var }