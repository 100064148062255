export default {


    async setCustomCard(lastname, firstname, nickname, organisation, job_role, email, phone, urls, image) {
        var org_line = ""
        var title_line = "";
        var title_line = "";
        var phone_line = "";

        var head = "BEGIN:VCARD\nVERSION:3.0" + "\n";
        var N = "N:"+lastname+";"+firstname+";;;" + "\n";
        var FN = "FN:"+ firstname + " " + lastname + "\n";
        var nickname_line = "NICKNAME:" +nickname + "\n";

        if (organisation !== null){
            org_line = "ORG:"+organisation+";" + "\n";
        }
        if (job_role !== null) {
            title_line = "TITLE:"+job_role+"" + "\n";
        }
        if (email !== null) {
            var email_line = "item1.EMAIL;type=INTERNET;type=pref:"+email+"\nitem1.X-ABLabel:E-mail" + "\n";

        }

        if (phone !== null) {
            phone_line = "item2.TEL;type=pref:"+phone+"\nitem2.X-ABLabel:Phone" + "\n";
        }
        
        var urls_line = ""
        for (var i = 0; i<urls.length; i++) {
            var url = "item"+i+3+".URL:" + urls[i].link + '\n'
                    + "item"+i+3+".X-ABLabel:" + urls[i].name
            
            urls_line += url + '\n';
   
        }

        var img_line = "PHOTO;ENCODING=b;TYPE=JPEG:" + await this.toDataURL(image) + "\n";
        var end = "END:VCARD"

        return head+N+FN+nickname_line+org_line+title_line+email_line+phone_line+urls_line+img_line+end;     

    },

    async setCard(userData, oItems) {
        var organisation = this.getSocialData('company', oItems);
        var job_role = null;
        var email = this.getSocialData('mail', oItems);
        var phone = this.getSocialData('phone', oItems);
        var urls = [
            {
                link: "https://amigo.bio/"+userData.url,
                name: "Amigo Profile" 
            }
        ];

        return await this.setCustomCard(userData.lastname, userData.firstname, userData.nickname, organisation, job_role, email, phone, urls, userData.image)

        // return await this.setCustomCard();
    },

    getSocialData(social, oItems) {
        for (var i = 0; i<oItems.length; i++) {
            if(oItems[i].social == 'mail' && social == 'mail') {
                return oItems[i].data.field1.split(":").pop();
            } else if (oItems[i].social == 'phone' && social == 'phone') {
                return oItems[i].data.field1.split(":").pop();
            } else if (social == 'company' && oItems[i].social == 'company') {
                return oItems[i].data.field1;
            }

        }
        return null;
    },


    async toDataURL(url) {
        const fetchAsBlob = url => fetch(url)
        .then(response => response.blob());

        const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
            const reader = new FileReader;
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });

        const blob = await fetchAsBlob(url)
        const doubleBase64EncodedFile =  await convertBlobToBase64(blob)
        const doubleEncodedBase64String = doubleBase64EncodedFile.split(',')[1]

        return doubleEncodedBase64String
        
        
        
    },

    
    getSocialsOnCard(vCard, oItems) {
 
        for (var i = 0; i<oItems.length; i++){
            if(oItems[i].social == 'mail') {
                vCard.email = oItems[i].data.field1.split(":").pop();
            } else if (oItems[i].social == 'phone') {
                vCard.cellPhone = oItems[i].data.field1.split(":").pop();
            } else {
                vCard.note += oItems[i].data.field1 + "\n";
            }
            
        }

        return vCard;
    },


    async downloadCard_Desktop(text, name) {
       
        var filename = name + '.vcf';
        
        var a = document.createElement("a") 
        var blob = new Blob([text], {type: 'text/vcard'});
        var blobURL = URL.createObjectURL(blob)
        a.download = filename
        a.href = blobURL
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

    },




    async downloadCard_Phone(text, name) {
        var filename = name + '.vcf';

        // var reader = new FileReader();
        
        // var out = new Blob([text], {name: filename, type: 'text/vcard'});

        // build data url
        var url = 'data:text/vcard;charset=utf-8,' + encodeURIComponent(text);

        // var d = filename;
        // $(this).attr("href", url).attr("download", d);

        $(document).ready(function(){
            var d = new Date().toISOString().slice(0, 19).replace(/-/g, "");
            $("#downloadlink").attr("href", url).attr("download", "file-" + d + ".vcf");
        });
        
        // ask the browser to download it
        
        //window.location.replace(url);

        

        // var isbContentType = 'text/vcard';

        // var reader = new FileReader();
        // reader.onload = function(e) {
        //    var bdata = btoa(reader.result);
        //    var datauri = 'data:' + isbContentType + ';base64,' + bdata;
        //    window.open(datauri);
           
        // };
        // reader.readAsBinaryString(new Blob([text], {type: 'text/vcard'}));


        // var reader = new FileReader();
        // var out = new Blob([text], {type: 'text/x-vcard'});
        // reader.readAsDataURL(out);
        // reader.onload = () => {
        //     // window.location='googlechrome-x-callback://x-callback-url/open/?url='+encodeURIComponent(reader.result)+'&x-source=Safari&x-success='+encodeURIComponent(reader.result);
        //     window.location.href = reader.result;
        // }

        // var reader = new FileReader();
        
        // var out = new Blob([text], {name: filename, type: 'text/vcard'});
        
        // var saveData = (function () {
        //     var a = document.createElement("a");
        //     document.body.appendChild(a);
        //     a.style = "display: none";
        //     return function (text, filename) {
        //             var blob = new Blob([text], {name: filename, type: 'text/vcard'});
        //             var url = window.URL.createObjectURL(blob);
        //         a.href = url;
        //         a.download = filename;
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     };
        // }());
        
        

        
        // saveData(text, filename);


        // reader.readAsDataURL(out);
        // reader.fileName = filename;

        // reader.onload = () => {
        //     window.location.href = reader.result;
        // }

        // const a = document.createElement('a');
        // a.setAttribute('download', filename);
        // a.setAttribute('href', window.URL.createObjectURL(out));
        // a.click(); //
        
        

        // var fileURL = URL.createObjectURL(out);
        // var a = document.createElement('a');
        // a.href = fileURL;
        // a.download = "hoi";
        // document.body.appendChild(a);
        // a.click();


    },

    

    

    // var filename = this.userData.name + '.vcf';
    

            // const myBase64 = atob(doubleEncodedBase64String)

    //
        // vCard.homeAddress.city = this.userData.city;
        // vCard.homeAddress.countryRegion = this.userData.country;
    
    // async downloadFile(filename,text) {
        // var element = document.createElement('a');
        // element.setAttribute('href', 'data:text/vcard;charset=utf-8,' + encodeURIComponent(text));
        // element.setAttribute('download', filename);

        // element.style.display = 'none';
        // document.body.appendChild(element);

        // element.click();

        // document.body.removeChild(element);

        // var reader = new FileReader();
        // var out = new Blob([text], {type: 'text/vcard'});
        // reader.onload = () => {
        //     window.location.href = reader.result;
        // }
        // reader.readAsDataURL(out);

        // var fileURL = URL.createObjectURL(out);
        // var a = document.createElement('a');
        // a.href = fileURL;
        // a.download = filename;
        // document.body.appendChild(a);
        // a.click();
        

        // var a = document.createElement("a") 
        // var blob = new Blob([text], {type: 'text/vcard'});
        // var blobURL = URL.createObjectURL(blob)
        // a.download = filename
        // a.href = blobURL
        // document.body.appendChild(a)
        // a.click()
        // document.body.removeChild(a)
        // var a;

        // a = document.getElementById("a");
        // a.href = "data:text/vcard," + encodeURIComponent(text);
        // a.download = filename;
        // a.target = "_blank";
        // a.textContent = "Download user's information";
    // },

    // rememberContact() {

    //     // localStorage.setItem('uuid', this.userData.id);
    //     // localStorage.setItem('url', this.userData.url);
    //     // var name = this.userData.name.split(" ")[0];
    //     // window.location.replace("/added?"+name);
        
    // },



}