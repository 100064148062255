

import {
  standard_acc,
  general,
  premium_socials,
  hospitality,
  username_addons,
  local_socials,
  maps_var
} from '../../General/Elements/Apps/social_media.js'


const FIRST = 0;

export default {
  getPropertyName(variable) {
    return Object.getOwnPropertyNames(variable)[0];
  },

  isReserved(uid) {
    if (uid === 'reserved') {
      return true
    }
    return false;
  },

  viaProduct(url) {
    if (url.split('=').length == 2) {
      return true;
    }
    return false;

  },

  setEmptyAccount() {
    var userData = {}
    userData.name = "Welcome to Amigo!"
    userData.bio = "This profile could be yours, <a class='text-link bold' href='/register' target='_blank'>claim your url now</a>!"
    userData.image = "images/pf.jpg"
    userData.noProfile = true;
    userData.score = 0;
    return userData;
  },

  async sortPosition(items, numberOfApps) {

    var x = [...Array(numberOfApps)].map(() => null)
    var counter = 0
    for (var i = 0; i < x.length; i++) {
      if (items[i] !== null) {
        x[counter] = items[i]
        x[counter].position = counter
        counter++;
      }
    }
    return x;
  },

  getLink(social, input, option) {

    if (social == null || input == null || option == null) {
      return null;
    }

    var link = "";
    if (option === "link") {
      if (
        /^https?:\/\//.test(input.toLowerCase()) ||
        /^http?:\/\//.test(input.toLowerCase())
      ) {
        link = input;
      } else {
        link = "http://" + input;
      }
    } else if (option === "URI") {
      link = input;
    } else if (option === "username") {
      if (social === "wechat") {
        link = "weixin://" + input;
      } else {
        link = "https://" + social + ".com" + username_addons[social] + input;
      }
    } else if (option === "number") {
      link = "tel:" + input;
    } else if (option === "mail") {
      link = "mailto:" + input;
    } else if (maps_var.includes(option)) {
      link = "https://maps.google.com/?q=" + input;
    } else {
      link = input;
    }

    return link;
  },

  objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
  },



  split_for_input(option, input) {


    if (option === 'username') {
      return input.split('/').pop();
    } else if (option === 'link') {
      return input;
    } else if (option === 'mail') {
      return input.split(':').pop();
    } else if (option === 'number') {
      return input.split(':').pop();
    } else if (maps_var.includes(option)) {
      return input.split('=').pop();
    } else {
      return input
    }
  },

  isOS() {
    // var isIOSChrome = winNav.userAgent.match("CriOS");

    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  },

  isChrome() {
    // please note, 
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;

    if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      return true
    } else {
      return false
    }

  },

  isChromeIOS() {
    var winNav = window.navigator;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    if (isIOSChrome) {
      return true
    }
    return false;
  },

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  },


  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  },

  rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  },


  hexToRgb(hex, opacity) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? "rgba(" + parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) + "," + opacity + ")" : null;
  },


  emptyFieldError(emptyField, variable, autocheck) {

    if (autocheck & (emptyField == null || emptyField == "")) {
      return "Pardone! You forgot to fill in the " + variable;
    } else {
      return "";
    }
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },


  async getAccountSocials(premium, view) {
    var socials = {
      uids: general.map((x) => {
        return x["social"];
      }),
      titles: general.map((x) => {
        return x["name"];
      }),
      options: general.map((x) => {
        return x["options"];
      }),
    };

    var account_socials = null;

    //start note: delete premium (view will cover all functionalities)
    if (premium) {
      // end note

      if (view == "hospitality") {
        account_socials = await this.getSocials(hospitality);
      } else {
        account_socials = await this.getSocials(premium_socials);
      }
    } else {
      account_socials = await this.getSocials(standard_acc);
    }
    return await this.mergeSocials(socials, account_socials)
  },

  async getSocials(input) {
    return {
      uids: input.map((x) => { return x['social']; }),
      titles: input.map((x) => { return x['name']; }),
      options: input.map((x) => { return x['options']; })
    }
  },

  async mergeSocials(socials, account_socials) {
    return {
      uids: socials['uids'].concat(account_socials['uids']),
      titles: socials['titles'].concat(account_socials['titles']),
      options: socials['options'].concat(account_socials['options'])
    }
  },


  async isFieldEmpty(field_array) {
    return await field_array.forEach((field) => {
      if (field.value == null) {
        return true;
      } else if (field.value == "") {
        return true;
      }

      return false;
    });
  },


  async convertDBToAppVariable(item, all_socials) {

    var index = await all_socials['uids'].indexOf(item.social)
    var options_array = await all_socials['options'][index]

    var fields = [];
    await options_array.forEach(async (options, index) => {
      var fieldname = "field" + (index + 1);
      fields[index] = {
        value: await this.split_for_input(options[0], item.data[fieldname]),
        options: options,
        option: options[0],
        link: item.data[fieldname],
      }
    });


    return await {
      info: {
        uid: item.social,
        title: item.title,
        position: item.position,
      },
      fields: fields
    }

  },

  dictToArray(dictionary, constant_var) {
    var count = 0;
    var array = [];
    var fieldname = null;
    for (var field in dictionary) {
      fieldname = constant_var + (count + 1)
      array.push(dictionary[fieldname])
      count = count + 1;
    }

    return array;
  },


  async getOptions(social, index) {
    const array = [general, standard_acc, premium_socials, hospitality]
    var options = null;

    for (var i = 0; i < array.length; i++) {
      options = await this.searchSocialOptions(array[i], social, index);
      if (options != null) {
        return options
      }
    }
  },


  async searchSocialOptions(collection, social, index) {
    var q = await collection.find((x) => {
      return (x['social'] == social)
    })
    return q != null ? q['options'][index][FIRST] : null;
  },

  async getSocialTitle(social) {
    const array = [general, standard_acc, premium_socials, hospitality]
    var title = null;

    for (var i = 0; i < array.length; i++) {
      title = await this.searchSocialTitle(array[i], social);
      if (title != null) {
        return title
      }
    }
  },

  async searchSocialTitle(collection, social) {
    var q = await collection.find((x) => {
      return (x['social'] == social)
    })
    return q != null ? q['name'] : null;
  },

  setPlaceholder(option, uid, placeholders) {
    if (option === "link") {
      return "https://" + uid + ".com/..";
    } else if (option === 'adress') {
      return "Fill in your streetname .."
    } else if (option === 'city') {
      return "Fill in your city .."
    } else if (option === 'country') {
      return "Fill in your country .."
    } else if (option === 'zip code') {
      return "Fill in your zip code .."
    } else {
      return placeholders[option];
    }
  },

  deepcopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepcopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.deepcopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
},

setScrolling(overflow, position, width) {
  document.getElementsByTagName("body")[0].style["overflow"] = overflow;
  document.getElementsByTagName("body")[0].style["position"] = position;
  document.getElementsByTagName("body")[0].style["top"] = 0;
  document.getElementsByTagName("body")[0].style["width"] = width;

  if (overflow == "hidden") {
    window.scrollTo(0, 0);
  }
},



setTag(gtag, app_url, app_name, type_of_social, page_url, initial_url, uid) {
  gtag.query("event", "app_click", {
    app_url: app_url,
    page_url: page_url,
    initial_url: initial_url,
    uid: uid,
    app_name: app_name,
    type_of_social: type_of_social,
  });
},













}



