<template>
  <div>
    <template v-if="item">
      <div
        class="tile_newsocial customize click"
        :class="getTileClass()"
        @mouseenter="dragStart"
        @mouseleave="dragEnd"
        @touchstart="dragStart"
        @touchend="dragEnd"
      >
        <div class="tile">
          <Icon :social="item.social" :colors_arr="color[2].icons"/>

          <div
            v-if="hovers === index"
            class="icon-hover draggable"
            :style="'background-color:' + color_rgba()"
          >
            <img src="images/option-white.png" loading="lazy" width="36" />
          </div>

          <div @touchstart="edit()" @click="edit()" class="delete-tile-div">
            <div class="change">
              <img
                src="images/edit-button-white.png"
                loading="lazy"
                class="edit-img"
              />
            </div>
          </div>
        </div>

        <div class="txt-div">
          <p class="white">{{ item.title }}</p>
        </div>
      </div>
    </template>
    <template v-else>
      <a @click="addSocial()" class="link-block-9">
        <EmptyTile />
      </a>
    </template>
  </div>
</template>

<script>
import EmptyTile from "./EmptyTile.vue";
import Icon from "./AppIcon.vue"

import methods from "../../Methods/General.js";

export default {
  components: {
    EmptyTile,
    Icon,
  },
  props: ["item", "index", "setOpacity", "offline", "color"],

  data: () => {
    return {
      colors: {
        color1: "#FFA500",
        color2: "#FFA500",
        background: "white",
      },

      hovers: -1,
    };
  },

  methods: {

    edit() {
      var object = {
        item: this.item,
        index: this.index,
      };
      this.$emit("edit", object);
    },

    color_rgba() {
      return methods.hexToRgb(this.color[0], 0.4);
    },

    getTileClass() {
      if (this.setOpacity == "opacity-low") {
        if (this.index == 0) {
          return "";
        }

        return "opacity-low";
      }

      return "";
    },

    addSocial() {
      if (this.offline) {
        this.$emit("newSocial", true);
      }
    },

    dragStart() {
      this.hovers = this.index;
    },

    dragEnd() {
      this.hovers = -1;
    },
  },
};
</script>

<style>
svg {
  width: 100%;
  height: 100%;
}

.component_class {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  padding: 5%;
}
</style>

