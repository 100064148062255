<template>
    <div class="footer-login">
        <div class="text-div center">
            <p class="paragraph bold">{{footer_title}}</p>
            <p class="paragraph">
                <a href="#/register" class="paragraph link">Register free</a> or <a href="https://amigo-connected.com" target="_blank" class="paragraph link">order an amigo product!</a>
            </p>
        </div>
    </div>
</template>

<script>


export default {
  data() {
      return {
          footer_title: "Don't have an account?",
      }
      
  },


};
</script>