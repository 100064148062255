<template>
<!-- Make a frame wrapped slider -->
 <div class="banner-slider w-slider">
      <!-- Configuring slider components -->
      <slider ref="slider" :options="options" class="w-slider-mask">
          <!-- slideritem slot -->
          <slideritem v-for="(i,index) in banner_input" :key="index" class="slide w-slide">
            <div class="banner-div">
              <a :href="i.link" target="_blank" class="banner-linkblock w-inline-block">
              <img loading="eager" :src="i.image" class="banner_image">
                <div class="text-banner-div">
                  <h4 class="bheading">{{i.head}}</h4>
                  <p class="bparagraph">{{i.body}}</p>
                </div>
              </a>
            </div>

          </slideritem>
          <!-- Set loading -->
          <div slot="loading">loading...</div>
      </slider>
 </div>
</template>
<script>
import { slider, slideritem } from 'vue-concise-slider'// import slider components
export default {
    components: {
      slider, 
      slideritem
    },
    props: [
      'banner_input'
    ],
    data: () => {
      return {
        options: {
          autoplay:3500,
          loop:true,
          speed: 1000,
          pagination: false,
          slidesToScroll:1,
          loopedSlides:1,
          centeredSlides: false,
          preventDocumentMove:false
        },
 
      }
    },

    methods: {



    //   // Listener event
    //   slide (data) {
    //     console.log(data)
    //   },
    //   onTap (data) {
    //     console.log(data)
    //   },
    //   onInit (data) {
    //     console.log(data)
    //   }
    }
}
</script>
