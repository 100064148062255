
<template>
  <div>
    <template v-if="!activated">
      <div class="background">
        <Header />
        <div class="page-body-2 without-footer center">
          <div class="form-div marge-up">
            <h1 class="heading1">{{ title }}</h1>
            <p class="paragraph">{{ description }}</p>
            <div class="form-block no-bottom-marge w-form">
              <div class="marge-down">
                <input
                  ref="code"
                  :style="{ 'border-color': codeBorder }"
                  type="text"
                  class="text-field nofocusborder w-input"
                  maxlength="9"
                  placeholder="E.g. AMIGO0789XYZ"
                  required="true"
                />
              </div>
            </div>

            <div>
              <a @click="next" class="submit-button w-button">{{ btn_txt }}</a>
            </div>
            <template v-if="error !== ''">
              <div>
                <div class="error-message">{{ error }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <SelectAccount :activationcode="code" :p_url="url" />
    </template>
  </div>
</template>


<script>
import db_url from "../General/DB/url.js";
import SelectAccount from "./SelectAccount.vue";

import Header from "../General/Elements/Headers/Header1.vue";

export default {
  components: {
    SelectAccount,
    Header,
  },
  data() {
    return {
      title: "Enter your activation code!",
      description:
        "You can find your activation code on the instruction card that came with your amigo product",
      btn_txt: "Activate account!",
      error: "",
      activated: false,
      url: "",
      code: "",
      codeBorder: "transparant",
    };
  },

  methods: {
    async next() {
      this.code = this.$refs.code.value.toLowerCase();
      const splitted_url = location.href.split("?");

      if (this.checkInput(splitted_url)) {
        const data = await db_url.getPublic(splitted_url[1]);
        const db_code = data.code;
        const uid = data.uid;

        if (this.code === db_code && uid === "reserved") {
          this.url = splitted_url[1];
          this.activated = true;
        } else {
          this.error = "Error: no valid code or url";
          this.codeBorder = "#ff0000";
        }
      } else {
        this.error = "Error: no valid code or url";
        this.codeBorder = "#ff0000";
      }
    },

    checkInput(url) {
      if ((url.length === 2) & (this.code !== "")) {
        return true;
      }
      return false;
    },
  },
};
</script>
