<template>
  <div class="background pop-up check">
    <div class="page-body add-social">
      <div class="white-div mobile">
        <Header heading="" @state="close" />
        <div class="configapp-div">
          <AppTitle :data="data" :color="color" />

          <div v-for="(field, index) in fields" in :key="index">
            <AppField
              button="copy"
              :data="field"
              :social="data.social"
              :index="index"
              @copy="doCopy(field)"
            />
          </div>
        </div>

      </div>
    </div>

    <template v-if="copy">
      <Copy text1="You copied: " :text2="field" :copy="true" @back="copy = false" />
    </template>
  </div>
</template>

<script>
import Header from "../Headers/PopUpHeader.vue";
import AppTitle from "./Title.vue";
import AppField from "./Field.vue";

import Copy from "../PopUps/Alert.vue";

import methods from "../../Methods/General.js";

export default {
  components: {
    Header,
    AppTitle,
    AppField,
    Copy,
  },
  props: ["data", "color"],

  data() {
    return {
      copy: false,
      fields: [],
      field: null,
    };
  },

  created() {
    this.fields = methods.dictToArray(this.data.data, "field");
    methods.setScrolling("hidden","fixed", "100%");
  },

  methods: {
    close() {
      methods.setScrolling("visible","static", "auto");
      this.$emit("back", true);
    },
    doCopy(field) {
      this.field = field;
      this.copy = true;
    },
  },
};
</script>
