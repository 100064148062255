<template>
  <div class="background whitebg">

    <div class="page-body-2 full">
      <div class="pop-up-block">
        <div id="icon-container" class="lottie-animation">
            <lottie-animation path="logo_animation.json"/>
        </div>
        <div class="text-block" style="z-index:9">
        </div>
      </div>
    </div>
  </div>
</template>


<script>


export default {
  components: {

  },

  created() {

  },
};
</script>
