
<template>
  <div
    class="background"
    :style="
      'background-image: url(\'../images/amigo_logo_outline_bg.svg\'), ' +
      'linear-gradient(311deg, ' +
      color[0] +
      ',' +
      color[1] +
      ');'
    "
  >
    <Header />
    <div class="header-logout">
      <div class="columns-2 w-row">
        <div class="w-col w-col-6">
          <div class="text-div _1">
            <a @click="goBack" class="button small w-inline-block">
              <img
                src="images/go-back.png"
                loading="lazy"
                sizes="(max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
                srcset="images/go-back-p-500.png 500w, images/go-back.png 512w"
                alt=""
                class="image-18"
              />
            </a>
          </div>
        </div>
        <div class="column w-col w-col-6">
          <div class="button-block logout"></div>
        </div>
      </div>
    </div>
    <div class="page-body _80vh center">
      <div class="center qr">
        <div class="max-height">
          <qr-code
            :text="this.amigo_url"
            bg-color="rgb(255,255,255,0)"
            color="#fff"
          >
          </qr-code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../General/Elements/Headers/Header1.vue";

export default {
  components: {
    Header,
  },

  data: () => {
    return {
      amigo_url: "",
    };
  },

  props: ["url", "color"],

  created() {
    this.amigo_url = "https://amigo.bio/" + this.url;
  },

  methods: {
    goBack() {
      this.$emit("goBack", true);
    },
  },
};
</script>
