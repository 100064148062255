

import json from '../../GlobalValues.json'
import {db, firebase} from '../../../firebase'


export default {

    //Contacts
    async setContacts(uid, data) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].contacts).doc(json['docs'].contacts).set({
            data: data
        })
        .catch((err) => {
            throw err.message;
        })
    },

    async deleteContacts(uid) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].contacts).doc(json['docs'].contacts).delete()
        .catch((err) => {
            throw err.message;
        })
    },

    async deleteContact(uid, contact) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].contacts).doc(json['docs'].socials).update({ 
            data: firebase.firestore.FieldValue.arrayRemove({"name": contact.name, "uid": contact.uid})
        })
        .catch((err) => {
            throw err.message;
        })
    },

    async addContact(uid, contact) {
        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].contacts).doc(json['docs'].contacts).update({
            data: firebase.firestore.FieldValue.arrayUnion({
                name: contact.name,
                uid: contact.uid,
            })
        })
        .then(()=>{
            return true;
        })
        .catch(() => {
            return false;
        })
    },

    

    async updateContacts(uid, data) {
        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].contacts).doc(json['docs'].contacts).update({data})
        .then(()=>{
            return true;
        })
        .catch(() => {
            return false;
        })
    },

    async getContacts(uid) {
        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].contacts).doc(json['docs'].contacts).get()
        .then((doc) => {
            return doc.data().data;
        })  
        .catch( () => {
            return null;
        })
    },

    async isExistingContact(contacts, contact_uid) {
        if (contacts.some(contact => contact.uid === contact_uid)) {
            return true;
        } else {
            return false;
        }
    },

    async countContacts(contacts) {
        if (contacts === null) {
            return 0;
        }
        return contacts.length;
    },

    async getContactInfo(uid) {
        return await db.collection(json['collections'].users).doc(uid).get()
        .then(doc => {
            var data = {
                nickname: doc.data().nickname,
                url: doc.data().url,
                image: doc.data().image,
                uid: doc.id
            }
            return data;
        })
        .catch(() => {
            return null;
        });
    },

}