

 
import contacts from '../user/contacts.js'
import socials from '../user/socials.js'
import data from '../user/data.js'
import vSocials from './visibleSocials.js'
import vData from './visibleData.js'
// import { delete } from 'vue/types/umd'


export default {
    contacts,
    data,
    socials,
    vSocials,
    vData,


    // firebase.firestore.FieldValue.delete()

    async setOffline(uid, publicdata, publicsocials) {

        //set public data
        delete publicdata.url
        delete publicdata.uid
        delete publicdata.score
        

        await data.updateData(uid, publicdata, 'public')
        .catch((err) => {
            
            throw err;
        })
        
        //get private data
        const privatedata = await data.getData(uid, 'private')
        .catch((err) => {
            throw err;
        })

        

        //set public data & socials
        await vData.updateData(uid, privatedata)
        .catch((err) => {
            throw err;
        });

        

        await socials.setData(uid, publicsocials, 'public')
        .catch((err) => {
            throw err;
        });

        await vSocials.resetSocials(uid)
        .catch((err) => {
            throw err;
        })

        const d = {bio: privatedata.bio, image: privatedata.image, nickname: privatedata.nickname, offline: privatedata.offline};
        const s = {data: [...Array(9)].map(() => null), redirect: false};
        
        

        return {d,s};
    },

    async setOnline(uid, privatedata) {

        delete privatedata.url
        delete privatedata.uid
        delete privatedata.score
        delete privatedata.email
        delete privatedata.firstname
        delete privatedata.lastname
        //set private data
        await data.updateData(uid, privatedata, 'private')
        .catch((err) => {
            
            throw err;
        })
        
        //get public data
        const publicdata = await data.getData(uid, 'public')
        .catch((err) => {
            throw err;
        })
        

        //set public data & socials
        await vData.updateData(uid, publicdata)
        .catch((err) => {
            throw err;
        });

        //get socials
        const publicsocials = await socials.getData(uid, 'public')
        .catch((err) => {
            throw err;
        });

    
        
        
        //set public socials
        await vSocials.updateSocials(uid, publicsocials.data)
        .catch((err) => {
            throw err;
        })

        

        const d = publicdata;
        const s = {data: publicsocials.data, redirect: false};

        return {d,s}


    },

    async registerUser(data, uid){
        return await this.addUser(data, uid)
        .catch(async (err) => {
            await this.deleteUser(err, uid);
        })
    },

    async addUser(i_data, uid) {
        
        delete i_data.uid;

        await vData.setData(uid, i_data)
        .catch(() => {
            throw 'data'
        });

        delete i_data.url;
        delete i_data.score;

        

        await vSocials.setSocials(uid, [])
        .catch(() => {
            throw 'socials'
        });

        await contacts.setContacts(uid, [])
        .catch(() => {
            throw 'contacts'
        });

        const privatedata = await Object.assign({}, i_data, await data.initPrivate());

        await data.setData(uid, privatedata, "private")
        .catch(() => {
            throw 'privateData'
        });



        await data.setData(uid, i_data, "public")
        .catch(() => {
            throw 'publicData'
        });

        await socials.setData(uid, [], "public")
        .catch(() => {
            throw 'publicSocials'
        });

        return true;

    },

    async deleteUser(err, uid) {
        if (err === 'data') {
            return true;
        } else if(err === 'socials') {
            await vData.deleteData(uid);
            return true;
        } else if (err === 'contacts') {
            await vData.deleteData(uid);
            await vSocials.deleteSocials(uid);
            return true;
        } else if (err === 'privateData') {
            await vData.deleteData(uid);
            await vSocials.deleteSocials(uid);
            await contacts.deleteContacts(uid);   
            return true;
        } else if (err === 'privateSocials') {
            await vData.deleteData(uid);
            await vSocials.deleteSocials(uid);
            await contacts.deleteContacts(uid);   
            await data.deleteData(uid, "private");
            return true;
        } else if (err === 'publicData') {
            await vData.deleteData(uid);
            await vSocials.deleteSocials(uid);
            await contacts.deleteContacts(uid);   
            await data.deleteData(uid, "private");
            await socials.deleteSocials(uid, "private");
            return true;
        } else if (err === 'publicSocials') {
            await vData.deleteData(uid);
            await vSocials.deleteSocials(uid);
            await contacts.deleteContacts(uid);   
            await data.deleteData(uid, "private");
            await socials.deleteSocials(uid, "private");
            await data.deleteData(uid, "public");
        } else if (err === 'publicSocials'){
            await vData.deleteData(uid);
            await vSocials.deleteSocials(uid);
            await contacts.deleteContacts(uid);   
            await data.deleteData(uid, "private");
            await socials.deleteSocials(uid, "private");
            await data.deleteData(uid, "public");
            await socials.deleteSocials(uid, "public");
            return true;
        }

        return false;
                            
            

        
    }



    




    

    

}
   


   