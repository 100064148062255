<template>
  <div class="_100 nobg appfieldinput">
    <div class="field-collection">
      <div class="field-div">
        <h1 class="app-title">{{ title }}</h1>
        <div class="addsocial-info-div">
          <input
            class="text-field copy-link w-input"
            type="text"
            disabled="true"
            :value="data"
            :maxlength="input_length[social]"
            :placeholder="placeholders[social]"
            required="true"
            :style="'cursor:' + cursor"
            
          />
          <a @click="doCopy()" class="submit-button copy w-button">{{
            button
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import json from "../../GlobalValues.json";

import methods from "../../Methods/General.js";

export default {
  props: ["data", "social", "button", "index"],
  data() {
    return {
      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholders"],

      cursor: "default",

      title: "",
    };
  },

  async created() {
    this.title = methods.capitalizeFirstLetter(await methods.getOptions(this.social, this.index)) + ":";
  },


  methods: {
    doCopy() {
      this.$emit("copy", true);
    },


  },
};
</script>

<style>


@media screen and (max-width: 479px) {
  .text-field.copy-link:disabled {
    margin-right:0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width:100%;
  }
}
</style>
