<template>
  <div class="page-body add-social">
    <div class="white-div large">
      <Header :heading="heading" @state="closeWindow" />

      <div class="allsocials">
        <div class="w-layout-grid gridsocials">
          <div v-for="(element, idx) in socials.uids" :key="idx">
            <a
              @click="selectSocial(element, idx)"
              class="tile_newsocial _11vh"
              :class="{ buttonactive: element == selected_social.info.uid }"
            >
              <div class="tile auto">
                <Icon :social="element" :colors_arr="color[2].icons" />
              </div>
              <div class="conf1-txt">{{ socials.titles[idx] }}</div>
            </a>
          </div>
        </div>
      </div>

      <div v-if="selected_social.info.uid !== null" class="div-block-44">
        <a @click="next()" class="submit-button w-button">{{ button }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../General/Elements/Headers/PopUpHeader.vue";
import Icon from "../../General/Elements/Apps/AppIcon.vue";

export default {
  components: {
    Header,
    Icon,
  },
  props: ["heading", "button", "socials", "color"],





  data: () => {
    return {
      selected_social: {
        info: {
          uid: null,
          title: null,
        },

        fields: [
          // {
          // options: [],
          // option: null,
          // },
        ],
      },
    };
  },

  methods: {

    closeWindow() {
      this.$emit("state", 0);
    },

    async selectSocial(element, idx) {
      this.selected_social.info = {
        uid: element,
        title: this.socials.titles[idx],
      };

      var option_array = this.socials.options[idx];
      await option_array.forEach(async (x) => {
        this.selected_social.fields.push({
          options: x,
          option: x[0],
          value: null,
          link: null,
        });
      });
    },

    next() {
      this.$emit("selected", this.selected_social);
    },
  },
};
</script>