
<template>
    <div>
        <Register
        :activationcode="null"
        :p_url="null"
        :c_url="url"/>
    </div>

   
</template>


<script>

import Register from './Register1.vue'


export default {
    components: {
        Register,
    },
    
    data() { 
        return { 
            url: "",
        };
    },
    
    created() {
        this.url = this.$route.fullPath.split("=").pop()
    },
  
};
</script>
