<template>
  <div class="page-body">
    <div class="img-and-social">
      <ProfilePicture :image="userData.image" :editMode="false" />
      <div class="transp-body">
        <DownloadCard
          :link="link"
          :download="download"
          :desktop="true"
          @alert="setAlert"
        />
      </div>
      <div class="profile-div">
        <Info
          :nickname="userData.nickname"
          :score="userData.score"
          :bio="userData.bio"
          :view="userData.view"
        />

        <div class="social-block no-marge">
          <DownloadCard
            :link="link"
            :download="download"
            :desktop="false"
            @alert="setAlert"
          />

          <div class="grid-block">
            <div class="w-layout-grid public-pf">
              <div v-for="(item, index) in profileData.items" :key="index">
                <Apps
                  :item="item"
                  :numberOfItems="numberOfItems"
                  :index="index"
                  :url="userData.url"
                  :initial_url="userData.initial_url"
                  :uid="uid"
                  :color="color"
                  @link="setLink"
                  @hidePopUp="setPopUp"
                  @isLocal="setLocal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="userData.banner">
      <Banner2 :banner_input="userData.banner" />
    </template>
    <template v-else>
      <Banner3 />
    </template>
  </div>
</template>




<script>
import card from "./VCFCard.js";
import methods from "../General/Methods/General.js";

import ProfilePicture from "../General/Elements/ProfilePicture.vue";
import Banner from "../General/Elements/Banners/Banner.vue";
import Banner2 from "../General/Elements/Banners/Banner2.vue";
import Banner3 from "../General/Elements/Banners/Banner2 - AMIGO facts.vue";

import Apps from "../General/Elements/Apps/App (Clickable).vue";
import Remember from "../General/Elements/Profile/RememberBtn.vue";
import Info from "../General/Elements/Profile/Info.vue";
import DownloadCard from "../General/Elements/Download/DownloadCard.vue";

export default {
  components: {
    ProfilePicture,

    Banner,
    Banner2,
    Banner3,

    Remember,
    Apps,
    Info,
    DownloadCard,
  },
  props: ["userData", "profileData", "numberOfItems", "uid", "color"],
  data: () => {
    return {
      data: {
        social: "",
        title: "",
        field1: "",
      },

      isLocal: false,

      download: null,
      link: null,
    };
  },

  async created() {
    this.setProfileDownload();
  },

  methods: {
    goRemember(value) {
      this.$emit("goRemember", value);
    },

    async setProfileDownload() {
      var text = await card.setCard(
        this.userData,
        this.profileData.items.filter((a) => a)
      );
      const blob = new Blob([text], { type: "text/vcard" });

      this.link = "data:text/vcard;charset=utf-8," + encodeURIComponent(text);
      this.download = this.userData.nickname + ".vcf";

      if (methods.isChromeIOS()) {
        this.link = "safari-https:" + URL.revokeObjectURL(blob);
      }
    },

    setLink(link) {
      this.$emit("link", link);
    },

    setPopUp(bool_val) {
      this.$emit("hidePopUp", bool_val);
    },

    setLocal(item) {
      this.$emit("isLocal", item);
    },

    setAlert(value) {
      this.$emit("alert", value);
    },
  },
};
</script>