<template>
  <component
    class="component_class"
    :style="'background:' + colors.background + '; padding:0%;'"
    :is="comp"
    v-bind="colors"
  ></component>
</template>


<script>
export default {
  props: ["social", "colors_arr"],

  created() {
    this.setColors();
    this.comp = this.getComponent();
  },

  watch: {
    social: {
      handler() {
        this.comp = this.getComponent();
      },
    },

    colors_arr: {
      handler() {
        this.setColors();
      },
    },
  },

  data: () => {
    return {
      colors: {
        color1: null,
        color2: null,
        background: null,
      },

      comp: null,
    };
  },

  methods: {
    setColors() {
      this.colors = {
        color1: this.colors_arr[0],
        color2: this.colors_arr[1],
        background: this.colors_arr[2],
      };
    },

    getComponent() {
      return () => import(`./App Icons (components)/${this.social}.vue`);
    },
  },
};
</script>
