var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.checkDelete)?[_c('Check',{attrs:{"list":_vm.list,"idx":_vm.id},on:{"deleteCheck":_vm.setDelete}})]:_vm._e(),_c('div',{staticClass:"background _100vh",style:('background-image: url(\'../images/amigo_logo_outline_bg.svg\'), ' +
      'linear-gradient(311deg, ' +
      _vm.color[0] +
      ',' +
      _vm.color[1] +
      ');')},[_c('HeaderAMIGO'),(_vm.setVariables)?[_c('div',{staticClass:"page-body center contacts"},[_c('Header',{attrs:{"title":"Your saved contacts","image":"images/shining.png"},on:{"back":function($event){return _vm.goToProfile()}}}),_c('div',{staticClass:"saved-contacts-div"},[_c('div',{staticClass:"scroll-div contacts"},[_c('draggable',{staticClass:"list w-list-unstyled",attrs:{"tag":"ul","list":_vm.list,"handle":".handle"}},_vm._l((_vm.list),function(element,idx){return _c('li',{key:idx,staticClass:"list-item"},[_c('div',{staticClass:"columns-7 w-row"},[_c('div',{staticClass:"center w-col w-col-2"},[_c('a',{staticClass:"link-block-6 w-inline-block handle"},[_c('img',{staticClass:"options-img",attrs:{"src":"images/drag.png","loading":"lazy"}})])]),_c('div',{staticClass:"center w-col w-col-5"},[_c('a',{staticClass:"name-link w-inline-block",on:{"click":function($event){return _vm.goLink(element.uid)}}},[_c('div',{staticClass:"max-size",style:({
                          'background-image':
                            'url(' + _vm.setPicture(element.uid) + ')',
                          'background-position': '50% 50%',
                          'background-size': 'cover',
                        })}),_c('div',{staticClass:"purple name"},[_vm._v(" "+_vm._s(_vm.setName(element.uid))+" ")])])]),_c('div',{staticClass:"center non-mobile w-col w-col-3"},[_c('a',{staticClass:"name-link w-inline-block",on:{"click":function($event){return _vm.goLink(element.uid)}}},[_c('div',{staticClass:"purple"},[_vm._v("visit profile")])])]),_c('div',{staticClass:"center w-col w-col-2"},[_c('a',{staticClass:"w-inline-block",on:{"click":function($event){return _vm.removeAt(idx)}}},[_c('img',{staticClass:"close-img small",attrs:{"src":"images/close-transparant-white.png","loading":"lazy","height":"","srcset":"images/close-transparant-white-p-500.png" + " 500w, " + "images/close-transparant-white.png" + " 512w","sizes":"(max-width: 479px) 17px, (max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw","alt":""}})])])])])}),0)],1)])],1)]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }