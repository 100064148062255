<template>
    <div class="background">
        <div class="logo-header-2 long">
        <div class="logo-pop-up"><img src="images/logo-amigo-icoon-wit-hq.png" loading="lazy" alt="" class="amigo-logo big">
            <div class="logo-text big">amigo</div>
        </div>
        </div>
        <div class="page-body-2 small">
        <div class="pop-up-block"><img src="images/verification.png" loading="lazy" alt="" class="image-22">
            <div class="text-block pop-up">
            <p class="text">Here you go amigo!</p>
            </div>
        </div>
        </div>
    </div>
</template>

<script>



export default {
    components: {
        
    },
    props: [

    ],
    data: () => {
      return {
        
      }
    },



    async created() {
       
        
    },


    
    methods: {

        



    }
}

</script>