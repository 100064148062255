<template>
  <div class="page-body add-social">
    <div class="white-div mobile">
      <Header :heading="heading" @state="closeWindow" />
      <div class="configapp-div">
        <AppTitle
          :social="social.info"
          :isNewApp="!app_exists"
          :color="color"
          @changeTitle="changeTitle"
        />

        <div v-for="(field, index) in social.fields" in :key="index">
          <ItemField
            :info="social.info"
            :field_input="field"
            :index="index"
            :isChange="app_exists"
            @changeOption="changeOption"
            @changeField="changeField"
            @submit="doSubmit"
          />
        </div>

        <template v-if="doRedirect && social.fields.length == 1">
          <Redirect
            button_text="preview"
            :info="social.info"
            :fields="social.fields"
          />
        </template>

        <div class="div-block-45">
          <a @click="doSubmit" class="submit-button w-button">{{ button }}</a>
          <template v-if="error !== ''">
            <Error :error="error" />
          </template>
        </div>

        <template v-if="app_exists">
          <div @click="deleteApp()" class="delete-app w-inline-block">
            <div class="icon-title delete">delete app</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { local_socials } from "../../General/Elements/Apps/social_media.js";
import methods from "../../General/Methods/General.js";

import Header from "../../General/Elements/Headers/PopUpHeader.vue";
import AppTitle from "../../General/Elements/AppConfig/AppTitle.vue";
import ItemField from "../../General/Elements/AppConfig/NewAppField.vue";
import Error from "../../General/Elements/Error-messages/StandardError.vue";
import Redirect from "../../General/Elements/AppConfig/Redirect.vue";

export default {
  components: {
    Header,
    AppTitle,
    ItemField,
    Error,
    Redirect,
  },

  props: ["heading", "button", "selected_social", "app_exists", "color"],

  data: () => {
    return {
      social: {
        info: {
          uid: null,
          title: null,
          position: null,
        },
        fields: [
          {
            value: null,
            options: [],
            option: null,
            link: null,
          },
        ],
      },

      doRedirect: false,

      isLoaded: false,

      error: "",
      state: 0,
    };
  },

  created() {
    this.social = this.selected_social;
    this.doRedirect = this.showRedirect();
  },

  methods: {
    closeWindow(value) {
      this.$emit("state", value);
    },
    changeOption(option) {
      this.social.fields[option.index].option = option.value;
    },

    changeField(field) {
      this.social.fields[field.index].value = field.value;

      var option = this.social.fields[field.index].option;
      this.error = methods.emptyFieldError(field.value, option, false);
      this.doRedirect = this.showRedirect();
    },

    changeTitle(value) {
      if (this.social.info.title != value) {
        this.social.info.title = value;
      }
    },

    async checkTitle() {
      if (this.social.info.title == "") {
        this.social.info.title = await methods.getSocialTitle(this.social.info.uid);
      }
    },

    async showRedirect() {
      if (local_socials.includes(this.social.info.uid)) {
        return false;
      } else if (await methods.isFieldEmpty(this.social.fields)) {
        return false;
      }

      return true;
    },

    deleteApp() {
      this.$emit("delete", true);
    },

    async doSubmit() {
      var db_fields = {};

      await this.social.fields.forEach(async (field, index, arr) => {
        this.error = await methods.emptyFieldError(
          field.value,
          field.option,
          true
        );

        if (this.error != "") {
          return;
        }

        await this.checkTitle();
        field.link = methods.getLink(
          this.social.info.uid,
          field.value,
          field.option
        );
        db_fields = this.convertFieldsForDB(db_fields, field, index + 1);

        if (arr.length - 1 === index) {
          var item = this.createItemObject(
            this.social.info.title,
            this.social.info.uid,
            db_fields,
            this.social.info.position
          );

          this.$emit("submit", item);
          return;
        }
      });
    },

    convertFieldsForDB(db_fields, field, i) {
      var name = "field" + i;
      db_fields[name] = field.link;

      return db_fields;
    },

    createItemObject(app_name, social, fields, position) {
      const item = {
        title: app_name,
        social: social,
        data: fields,
        position: position,
      };

      return item;
    },
  },
};
</script>