
<template>
  <div>
    <div class="background menu height" 
    :style="
          'background:' +
          'linear-gradient(311deg, ' +
          color[0] +
          ',' +
          color[1] +
          ');'
        ">
      <div class="content">
        <div>
          <div class="header-column w-row">
            <div class="left-col menu w-col w-col-9">
              <LeftHeader />
            </div>
            <div class="right-col close-add-tile w-col w-col-3">
              <div class="settings-div">
                <a @click="handleClose()" class="link-block w-inline-block">
                  <img
                    src="images/close-white.png"
                    loading="lazy"
                    class="settings-image"
                  />
                </a>
              </div>
            </div>
          </div>
          <div style="margin-top: 5%" class="menu-upper">
            <a
              v-if="onConfig && !onContact"
              @click="goContacts"
              class="menu-items"
            >
              <img
                src="images/icon-contacts.svg"
                loading="lazy"
                class="menu-icon"
              />
              <h1 class="heading-4 white left">Contacts</h1>
            </a>
            <a
              v-if="!onConfig | onContact"
              @click="goProfile"
              class="menu-items"
            >
              <img
                src="images/icon-account.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-4 white left">Profile</h1>
            </a>
            <a
              v-if="onConfig && !onContact"
              @click="goAccount"
              class="menu-items"
            >
              <img
                src="images/icon-account.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-4 white left">Account</h1>
            </a>
            <a v-if="onConfig" @click="goQR" class="menu-items">
              <img
                src="images/icon-qrcode.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-4 white left">QR code</h1>
            </a>
            <a v-if="onConfig" @click="doCopy" class="menu-items">
              <img
                src="images/icon-short-link.svg"
                loading="lazy"
                alt=""
                class="menu-icon"
              />
              <h1 class="heading-4 white left">Account link</h1>
            </a>
          </div>
        </div>
        <div class="menu-bottom">
          <a
            href="https://shop.amigo-connected.com"
            target="_blank"
            class="menu-items _2"
          >
            <img
              src="images/icon-shopping-cart.svg"
              loading="lazy"
              alt=""
              class="menu-icon"
            />
            <h1 class="heading6 white left">Webshop</h1>
          </a>
          <a @click="logout" class="menu-items _2">
            <img
              src="images/icon-log-out.svg"
              loading="lazy"
              alt=""
              class="menu-icon small"
            />
            <h1 class="heading6 white left">Log out</h1>
          </a>
        </div>
      </div>
    </div>
    <template v-if="copy">
      <Copy text1="You copied your personal link: " :text2="'amigo.bio/'+this.url" :copy="true" @back="copy = false" />
    </template>
  </div>
</template>


<script>
import db_auth from "../General/DB/auth.js";

import LeftHeader from "../General/Elements/Headers/LeftHeader.vue";
import Copy from "../General/Elements/PopUps/Alert.vue";

export default {
  components: {
    LeftHeader,
    Copy,
  },
  data() {
    return {
      copy: false,
    };
  },
  props: ["url", "showMenu", "onConfig", "onContact", "color"],
  computed: {},
  methods: {
    doCopy() {
      this.copy = true;
    },

    handleClose() {
      if (this.showMenu) {
        this.$emit("showMenu", false);
      }
    },
    async logout() {
      await db_auth.logout();
    },

    goAccount() {
      this.$emit("goAccount", true);
      this.$emit("setOff", false);
    },

    goQR() {
      this.$emit("goQR", true);
      this.$emit("setOff", false);
    },

    goProfile() {
      this.$emit("goProfile", true);
    },

    goContacts() {
      this.$emit("goContacts", true);
    },
  },
};
</script>
