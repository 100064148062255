<template>
  <div class="text-block">
    <h1 id="name" class="heading6">{{ nickname }}</h1>
    <template v-if="view != 'hospitality'">
      <div class="add-connect">
        <div class="score">
          <img
            src="images/fire-01.svg"
            loading="lazy"
            alt="AMIGO CONNECTED"
            class="image-16"
          />
          <div class="txt">{{ score }}</div>
        </div>
        <p class="txt-bio small">amigos</p>
      </div>
    </template>
    <p class="txt-bio" v-html="bio">{{ bio }}</p>
  </div>
</template>

<script>
export default {
  props: ["nickname", "score", "bio", "view"],
};
</script>
