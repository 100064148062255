<template>
  <div>
    <template v-if="item">
      <template v-if="isExternalSocial(item)">
        <a
          :href="getLink(item.data.field1, index)"
          :target="getTarget(item.social)"
          @click="setTag(item, index)"
          class="tile_newsocial customize click"
        >
          <div class="tile">
            <Icon :social="item.social" :colors_arr="color[2].icons" />
          </div>
          <div class="txt-div">
            <div class="white">{{ item.title }}</div>
          </div>
        </a>
      </template>
      <template v-else>
        <div v-if="setPopUp(index)" @click="setLocal(item)">
          <LocalTile :item="item" :color="color" />
        </div>
      </template>
    </template>

    <template v-else>
      <EmptyTile />
    </template>
  </div>
</template>

<script>
import LocalTile from "./LocalLink.vue";
import EmptyTile from "./EmptyTile.vue";
import Icon from "./AppIcon.vue"

import { local_socials } from "./social_media.js";
import methods from "../../Methods/General.js";

export default {
  components: {
    LocalTile,
    EmptyTile,
    Icon,
  },
  props: ["item", "item_length", "index", "url", "initial_url", "uid", "color"],

  data: () => {
    return {};
  },

  methods: {
    isExternalSocial(item) {
      if (local_socials.includes(item.social)) {
        return false;
      }
      return true;
    },

    setTag(item, index) {
      methods.setTag(
        this.$gtag,
        this.getLink(item.data.field1, index),
        item.title,
        item.social,
        this.url,
        this.initial_url,
        this.uid
      );
    },

    getLink(link, index) {
      if (index === 0) {
        this.$emit("link", link);
      } else if (index === this.numberOfItems - 1) {
        this.$emit("hidePopUp", true);
      }
      return link;
    },

    setLocal(item) {
      this.$emit("isLocal", item);
    },

    getTarget(social) {
      if (social === "mail" || social === "phone") {
        return "";
      }
      return "_blank";
    },

    setPopUp(index) {
      if (index === this.numberOfItems - 1) {
        this.$emit("hidePopUp", true);
      }
      return true;
    },
  },
};
</script>
