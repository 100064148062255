<template>
    <a
      @click="redirectWebsite()"
      target="_blank"
      class="open-app w-inline-block"
    >
      <div class="icon-title">{{button_text}}</div>
      <img src="images/link.png" loading="lazy" class="open-app-icon" />
    </a>
</template>

<script>

import methods from "../../Methods/General.js";

export default {
  props: ["button_text", "info","fields"],

  methods: {
    redirectWebsite() {
      var link = methods.getLink(this.info.uid, this.fields[0].value, this.fields[0].option);
      window.open(link);
    },
  }
}


</script>