
import json from '../../GlobalValues.json'
import {db} from '../../../firebase'


export default {

    //public data

    async initPrivate() {
        return await {
            nickname: 'Offline Profile',
            bio: 'I am currently unavailable..',
            image: '../images/pf.jpg',
            offline: true,
            email: "",
            firstname : "",
            lastname: ""
        }
    },

    async setData(uid, data, group) {
        
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].data).doc(group).set(data)
        .catch((err) => {
            throw err.message;
        });
    },

    async updateData(uid, data, group) {
        
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].data).doc(group).update(data)
        .catch((err) => {
            throw err.message;
        });
    },

    async deleteData(uid, group) {
        await db.collection(json['collections'].users).doc(uid).collection(json['collections'].data).doc(group).delete()
        .catch((err) => {
            throw err.message;
        })
    },

    async getData(uid, group) {
   
        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].data).doc(group).get()
        .then(doc => {
           
            if (doc.exists) {
                var data = doc.data();
                return data;
            }
        })
        .catch((err) => {
            throw err.message;
        })
    },

    async getPrivateUserData(uid) {

        return await db.collection(json['collections'].users).doc(uid).collection(json['collections'].data).doc('public').get()
        .then(doc => {
           
            if (doc.exists) {
                var email = doc.data().email;
                var firstname = doc.data().firstname;
                var lastname = doc.data().lastname;

                return {email, firstname, lastname};
            }
        })
        .catch((err) => {
            throw err.message;
        })



    }

}