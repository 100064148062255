<template>
  <div class="social-block no-marge" style="width:100%;">
    <template v-if="!isChromeIOS">
      <div class="button-block" :class="getFormat()">
        <a :href="link" :download="download" class="button w-inline-block">
          <img src="images/download.png" alt="AMIGO NFC" class="image-17 _2" />
          <div class="button-txt">{{ bnt_txt }}</div>
        </a>
      </div>
    </template>
    <template v-else>
      <div class="button-block" :class="getFormat()">
        <a @click="setAlert" class="button w-inline-block">
          <img src="images/download.png" alt="AMIGO NFC" class="image-17 _2" />
          <div class="button-txt">{{ bnt_txt }}</div>
        </a>
      </div>
    </template>
  </div>
</template>

<script>

import methods from "../../Methods/General.js";



export default {
  props: ["link", "download", "desktop"],

  data: () => {
      return {
          bnt_txt: "Add contact",
      }
  },

  computed: {
    isChromeIOS() {
      return methods.isChromeIOS();
    },
  },

  methods: {
    setAlert() {
      this.$emit("alert", true);
    },

    getFormat() {
        if (this.desktop) {
            return "desktop-small"
        } else {
            return ""
        }
    }
  },
};
</script>
