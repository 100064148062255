<template>
  <div class="title-div contacts">
    <div class="button-back-block">
      <a @click="back" class="button small w-inline-block">
        <img
          src="images/go-back.png"
          loading="lazy"
          sizes="(max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
          srcset="images/go-back-p-500.png 500w, images/go-back.png 512w"
          alt=""
          class="image-18"
        />
      </a>
    </div>
    <div class="title-block-div">
      <img v-if="image!=null"
        :src="image"
        loading="lazy"
        width="26"
        class="image-24"
      />
      <h1 class="heading-4 left white">{{title}}</h1>
    </div>
  </div>
</template>


<script>

export default {
  components: {
  },
  props: ["title", "image"],
  data() {
    return {
    }
  },
  methods: {
      back() {
          this.$emit('back', true)
      }
  }
}

</script>