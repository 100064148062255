
<template>
    <div class="background pop-up check">
        <div class="div-block-15">
        <div class="text-block-8">
            <strong>You will </strong><span class="text-span"><strong>delete</strong></span><strong> {{list[idx].name}}</strong></div>
            <div class="text-block-8">are you sure?</div>
        <div class="div-block-16">
            <a @click="deleteUser" id="deleteButton" href="#" class="button delete left w-button">Delete contact</a>
            <a @click="cancel" data-w-id="689d4bf3-e18c-104e-c58e-fe94154cb248" href="#" class="button delete cancel w-button">Cancel</a>
        </div>
        </div>
    </div>
</template>


<script>

import { db, firebase } from '../firebase'

export default {
  data() {
    return {
    };
  },
  props: [
      'list',
      'idx',
  ],



  methods: {
      async deleteUser(){
        const savesRef = db.collection("users").doc(this.list[this.idx].uid);
        const MINUS_ONE = -1;
        await savesRef.update({ 
            score: firebase.firestore.FieldValue.increment(MINUS_ONE)
        })
        this.list.splice(this.idx, 1);
        
        this.$emit('deleteCheck',false);
      },
      cancel(){
          this.$emit('deleteCheck',false);
      }
  }
};
</script>
