<template>
  <div>
    <div class="header-column w-row">
      <div class="left-col max-height w-col w-col-8">
        <div class="logo-header">
          <div class="logo-div">
            <img
              class="logo-img"
              src="images/logo-amigo-icoon-wit-hq.png"
              loading="lazy"
            />
            <div class="logo-text">amigo</div>
          </div>
          <template v-if="showDescription">
            <div class="slogan-text">simply connected</div>
          </template>
        </div>
      </div>
      <div
        class="right-col max-height align-center right-padding w-col w-col-4"
      >
        <div class="header-div">
          <template v-if="editorMode != null">
            <div class="settings-div right">
              <a @click="setIcon()" class="link-block w-inline-block">
                <img :src="icon" loading="lazy" class="settings-image" />
              </a>
            </div>
          </template>
          <div class="settings-div">
            <a @click="setMenu()" class="link-block w-inline-block">
              <img
                :src="menu_icon"
                loading="lazy"
                class="settings-image"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>



<script>
import LeftHeader from "./LeftHeader.vue";

export default {
  components: {
    LeftHeader,
  },
  data: () => {
    return {
      icon: "images/unlock-padlock.svg",

      LOCK: "images/padlock.svg",
      UNLOCK: "images/unlock-padlock.svg",
    };
  },

  props: ["editorMode", "showDescription", "menu_icon"],

  created() {
    if (!this.editorMode) {
      this.icon = this.LOCK;
    } else {
      this.icon = this.UNLOCK;
    }
  },

  methods: {
    setIcon() {
      if (this.editorMode) {
        this.icon = this.LOCK;

        this.$emit("editorMode", false);
      } else {
        this.icon = this.UNLOCK;
        this.$emit("editorMode", true);
      }
    },

    setMenu() {
      this.$emit("menu", true);
    },
  },
};
</script>