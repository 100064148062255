<template>
  <div class="social-block">
    <template v-if="isLocal">
      <LocalSocial :data="data" :color="color" @back="localBack" />
    </template>
    <div class="grid-block">
      <template v-if="checkedValue">
        <Redirect :title="items[0].title" />
      </template>
      <div class="w-layout-grid public-pf">
        <div v-for="(item, index) in items" :key="index">
          <Apps
          :item="item"
          :numberOfItems="oItemsLength"
          :index="index"
          :url="userData.url"
          :initial_url="userData.initial_url"
          :uid="uid"
          :color="color"
          @link="setLink"
          @hidePopUp="setPopUp"
          @isLocal="setLocal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalSocial from "../General/Elements/LocalSocial.vue";
import Redirect from "../General/Elements/Redirect/Note.vue";
import Apps from "../General/Elements/Apps/App (Clickable).vue"

export default {
  components: {
    LocalSocial,
    Redirect,
    Apps,
  },
  data: () => {
    return {
      link: "",
      savedLink: "",

      data: {
        social: "",
        title: "",
        field1: "",
      },

      isLocal: false,
    };
  },
  props: ["items", "borderColor", "checkedValue", "userData", "uid", "color"],
  
  created() {
    if (this.items[0] === null) {
      this.$emit("hidePopUp", true);
    }
  },

  computed: {
    oItems() {
      return this.items.filter((a) => a);
    },
    oItemsLength() {
      return this.oItems.length;
    },
  },
  methods: {
    
    setLink(link) {
      this.$emit("link", link);
    },

    setPopUp(bool_val) {
      this.$emit("hidePopUp", bool_val);
    },

    setLocal(item) {
      this.data = item;
      this.isLocal = true;
    },

    localBack(value) {
      this.isLocal = !value;
    },
  },
};
</script>