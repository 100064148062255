<template>
  <div class="background">
    <Header />

    <template v-if="isLoaded">
      <ChangeApp
        heading="Change App"
        button="Save changes"
        :selected_social="selected_social"
        :app_exists="true"
        :color="color"
        @submit="doSubmit"
        @state="closeWindow"
        @delete="deleteApp"
      />
    </template>
  </div>
</template>

<script>
import Header from "../../General/Elements/Headers/HeaderAMIGO.vue";
import ChangeApp from "./setAppData.vue";

import methods from "../../General/Methods/General.js";

export default {
  components: {
    Header,
    ChangeApp,
  },
  props: ["i_item", "index", "premium", "view", "color"],
  data: () => {
    return {
      item: {
        data: {
          field1: null,
        },
        title: null,
        social: null,
        position: null,
        type: null,
      },

      selected_social: {
        info: {
          uid: null,
          title: null,
          position: null,
        },
        fields: [
          {
            value: null,
            options: [],
            option: null,
            link: null,
          },
        ],
      },

      isLoaded: false,
    };
  },

  async created() {
    var socials = await methods.getAccountSocials(this.premium, this.view);
    this.selected_social = await methods.convertDBToAppVariable(
      this.i_item,
      socials
    );
    this.isLoaded = true;

  },

  methods: {
    closeWindow() {

      this.$emit("back", true);
    },

    deleteApp() {
      this.$emit("delete", this.selected_social.info.position);
    },

    doSubmit(value) {

      this.$emit("change", value);
    },
  },
};
</script>