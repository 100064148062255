
<template>
    <div>
        <template v-if="!isSuccess">
            <div class="background">
                <Header/>
                <div class="page-body-2 center">
                <div class="form-div width-cap">
                    <h1 class="heading1">Reset your password</h1>
                    <p class="paragraph">No problemo, we wil send<br>you an email</p>
                    <div class="form-block w-form" style="margin-bottom:5%">
                        <form>
                            <div class="paragraph left" style="margin-bottom: 1%; margin-left:1%">{{field_names.email}}</div>
                            <input ref="email" type="email" class="text-field marge-between w-input" :maxlength="input_length.email" :placeholder="field_names.email">
                        </form>
                    </div>
                    <div>
                    <a @click="resetEmail" class="submit-button w-button">Send me an e-mail!</a>
                    </div>
                    <template v-if="error!==''">
                        <div>
                            <div class="error-message">{{error}}</div>
                        </div>
                    </template>
                </div>
                </div>
                <div class="footer-login">
                <div class="text-div center horizontal">
                    <p class="paragraph">Go back to</p>
                    <a href="\login" class="paragraph bold marge">
                        login <img src="images/right-arrow.png" loading="lazy" class="image-20">
                    </a>
                </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="background pop-up">
                <div class="logo-header-2">
                <div class="logo-pop-up">
                    <img src="images/logo-amigo-icoon-wit-hq.png" loading="lazy" alt="" class="amigo-logo">
                    <div class="logo-text">amigo</div>
                </div>
                </div>
                <div class="page-body-2">
                <div class="pop-up-block">
                    <img src="images/thanksflysparks.png" loading="lazy" class="image-9">
                    <div class="text-block pop-up">
                    <p class="text">There you go!</p>
                    <p class="paragraph">We sent you an e-mail, <br>with the link in the mail you can change your password!</p>
                    </div>
                </div>
                </div>
            </div>
        </template>
    </div>
</template>


<script>

import db_auth from '../General/DB/auth.js'

import Header from './Elements/Headers/Header1.vue'

import json from '../General/GlobalValues.json'

export default {
    components: {
        Header,
    },
    data() { 
        return { 
            error: '',
            isSuccess: false,

            field_names: json["field_names"],
            input_length: json["input_length"],
            placeholders: json["placeholdrs"],
        };
    },

    methods: {
        async resetEmail() {
            this.isSuccess = await db_auth.resetEmail(this.$refs.email.value)
            if (this.isSuccess) {
                this.error = "Sorry something went wrong, please try it again!"
            }
        },
        
    },

};
</script>
