import json from '../GlobalValues.json'
import {firebase} from '../../firebase'
 
export default {

    async addProfilePicture(uid, image) {
        if (image === '') {
            return '../images/pf.jpg';
        }


        var image_url = "";

        var storage = firebase.storage();
        var storageRef = storage.ref(json["db_path"].profile_picture + uid);
        
        await storageRef.put(image)
        .catch((err) => {
            throw err.message;
        });
        await storageRef.getDownloadURL()
        .then((x) => {
            image_url = x;
        });
        return image_url;
    },

    async deleteProfilePicture(uid) {
        var storage = firebase.storage();
        var storageRef = storage.ref(json["db_path"].profile_picture + uid);
        await storageRef.delete()
        .catch(() => {
            // do nothing
        });
    },

    async updateProfilePicture(uid, image) {
        var image_url = "";

        var storage = firebase.storage();
        var storageRef = storage.ref(json["db_path"].profile_picture + uid);
        
        await storageRef.put(image)
        .catch((err) => {
            throw err.message;
        });
        await storageRef.getDownloadURL()
        .then((x) => {
            image_url = x;
        });
        return image_url;
    },

}
   


   