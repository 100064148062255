
<template>
    <div class="background pop-up check">
        <div class="div-block-15">
            <div class="text-block-8"><strong>You will </strong><span class="text-span"><strong>delete</strong></span><strong> your account permanently!</strong></div>
            <div class="text-block-8">are you sure?</div>
            <div class="form-block-2 w-form">
                <div>
                    <div class="paragraph left black">{{field_names.password}}</div>
                    <input v-on:keyup.enter="deleteUser" ref="password" type="password" class="text-field delete w-input" :maxlength="input_length.password" :placeholder="placeholders.password">
                </div>
                <div v-if="error!==''" class="error-message">{{error}}</div>
            </div>
            <div class="div-block-16">
                <a @click="deleteUser" class="button delete w-button">Delete account</a>
                <a @click="cancel" class="button delete cancel w-button">Cancel</a>
            </div>
        </div>
    </div>
</template>


<script>

import json from '../General/GlobalValues.json'

import db_auth from '../General/DB/auth.js'
import main_db from '../General/DB/main.js'

import db_user from '../General/DB/user/main.js'
import db_url from '../General/DB/url.js'
import db_storage from '../General/DB/storage.js'

export default {
  data() {
    return {
        field_names: json["field_names"],
        input_length: json["input_length"],
        placeholders: json["placeholders"],

        error: "",
    };
  },
  props: [
      'url',
      'user',
  ],


  methods: {
      async deleteUser() {
        var isAuthenticated = await db_auth.reauthenticate(this.user, this.$refs.password.value)
        .catch((err) => {
            this.error = err;
        })
        
        if (isAuthenticated) {
            await main_db.deleteContacts(this.user.uid)
            .catch((err) => {
                this.error = err;
            })

            await db_user.vSocials.deleteSocials(this.user.uid)
            .catch((err) => {
                this.error = err;
            })

            await db_storage.deleteProfilePicture(this.user.uid)
            .catch((err) => {
                this.error = err;
            })

            await db_url.resetUrl(this.user.uid)
            .catch((err) => {
                this.error = err;
            })

            await main_db.deleteData(this.user.uid)
            .catch((err) => {
                this.error = err;
            })

            await db_auth.deleteUser(this.user)
            .catch((err) => {
                this.error = err;
            })

            
        }
    },


    
    cancel() {
        this.$emit('getCheckValue', false);
    }

  }
};
</script>
