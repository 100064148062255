

<template>
  <div>
    <template v-if="isEditSocial">
      <EditSocial
        :i_item="item"
        :index="index"
        :premium="userData.premium"
        :view="userData.view"
        :color="userData.color"
        @back="setEdit(false)"
        @delete="handleDelete"
        @change="changeItem"
      />
    </template>

    <template v-else-if="addSocial">
      <AddNewSocial
        :premium="userData.premium"
        :view="userData.view"
        :color="userData.color"
        @mode="setAddSocial(false)"
        @item="setNewItem"
      />
    </template>

    <template v-else>
      <div
        :style="
          'background-image: url(\'../images/amigo_logo_outline_bg.svg\'), ' +
          'linear-gradient(311deg, ' +
          userData.color[0] +
          ',' +
          userData.color[1] +
          ');'
        "
        class="background"
      >
        <Header
          :editorMode="editorMode"
          :showDescription="true"
          menu_icon="images/setting.png"
          @menu="setMenu"
          @editorMode="setState"
        />

        <template v-if="!editorMode">
          <div class="page-body">
            <div class="img-and-social">
              <ProfilePicture
                :image="userData.image"
                :editMode="false"
                :color="userData.color[0]"
                @image="setImage"
              />
              <div class="transp-body"></div>
              <div class="profile-div">
                <div class="text-block">
                  <h1 class="heading6">{{ userData.nickname }}</h1>

                  <div
                    v-if="userData.view != 'hospitality'"
                    class="add-connect"
                  >
                    <div class="score">
                      <img
                        src="images/fire-01.svg"
                        loading="lazy"
                        class="image-16"
                      />
                      <div class="txt">{{ userData.score }}</div>
                    </div>
                    <p class="txt-bio small">amigos</p>
                  </div>
                  <p class="txt-bio">{{ userData.bio }}</p>
                </div>
                <Profile
                  :items="profileData.items"
                  :borderColor="profileData.borderColor"
                  :checkedValue="profileData.redirect"
                  :userData="userData"
                  :uid="user.uid"
                  :color="userData.color"
                  @hidePopUp="hidePopUp"
                />
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="page-body">
            <div class="img-and-social">
              <ProfilePicture
                :image="userData.image"
                :editMode="true"
                :color="userData.color[0]"
                @image="setImage"
              />

              <div class="transp-body">
                <div
                  class="add-connect redirect"
                  v-if="profileData.items[0] !== null"
                  :style="{ cursor: 'pointer', zIndex: 99 }"
                  @change="changeRedirect()"
                >
                  <p class="txt small left">
                    Redirect to <br />{{ profileData.items[0].title }}
                  </p>
                  <div class="html-embed w-embed">
                    <label class="switch">
                      <input type="checkbox" :checked="profileData.redirect" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>

                <div v-if="page === 1" class="arrow-div left">
                  <a
                    @click="slideState(setSState('right'))"
                    class="link-block-9 w-inline-block"
                  >
                    <img
                      src="images/next.svg"
                      loading="lazy"
                      class="arrow-img left"
                    />
                  </a>
                </div>
                <div v-if="page === 0" class="arrow-div right">
                  <a
                    @click="slideState(setSState('left'))"
                    class="link-block-9 w-inline-block"
                  >
                    <img
                      src="images/next.svg"
                      loading="lazy"
                      class="arrow-img"
                    />
                  </a>
                </div>
              </div>
              <div class="profile-div">
                <div class="text-block">
                  <input
                    ref="nickname"
                    @change="setNName()"
                    type="text"
                    :maxlength="input_length.nickname"
                    :value="userData.nickname"
                    placeholder="Amigo Profile"
                    class="text-field transparant w-input"
                  />

                  <div
                    v-if="userData.view != 'hospitality'"
                    class="add-connect"
                  >
                    <div class="score">
                      <img
                        src="images/fire-01.svg"
                        loading="lazy"
                        alt=""
                        class="image-16"
                      />
                      <div class="txt">{{ userData.score }}</div>
                    </div>
                    <p class="txt-bio small">amigos</p>
                  </div>
                  <input
                    style="margin: 5% 0 5% 0"
                    ref="bio"
                    @change="setBio()"
                    type="text"
                    :maxlength="input_length.bio"
                    :value="userData.bio"
                    placeholder="Let's connect!"
                    class="text-field transparant desc w-input"
                  />
                </div>

                <Swipe
                  :mainItems="profileData.items"
                  :borderColor="profileData.borderColor"
                  :numberOfItems="oItemsLength"
                  :uid="userData.uid"
                  :offline="userData.offline"
                  :color="userData.color"
                  :numberOfApps="userData.numberOfApps"
                  @newSocial="setAddSocial(true)"
                  @itemUpdate="setItems"
                  @editTile="editTile"
                />
              </div>
            </div>
          </div>
        </template>

        <div v-if="setLock" class="div-block-68 center">
          <img class="image-31" src="images/invisible-01.svg" loading="lazy" />
        </div>
        <div v-if="setUnLock" class="div-block-68 center">
          <img
            class="image-31"
            src="images/visibility_white.png"
            loading="lazy"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
//pages
import AddNewSocial from "./ConfigureSocial/Main.vue";
import EditSocial from "./ConfigureSocial/EditSocial.vue";

import Profile from "./Profile.vue";
import Swipe from "./Swipe.vue";

//elements
import Header from "../General/Elements/Headers/EditorModeHeader.vue";
import ProfilePicture from "../General/Elements/ProfilePicture.vue";

import db_user from "../General/DB/user/main.js";
import db_storage from "../General/DB/storage.js";

import json from "../General/GlobalValues.json";
import methods from "../General/Methods/General.js";

import Offline from "../Configuration/Offline.vue";

export default {
  components: {
    Swipe,
    Profile,
    AddNewSocial,
    Header,
    ProfilePicture,
    EditSocial,
    Offline,
  },

  props: ["user_info", "social_data", "user"],

  data: () => {
    return {
      userData: {
        image: "",
        nickname: "",
        bio: "",
        score: 0,
        uid: "",
        color: [],
        premium: false,
        numberOfApps: 9,
      },

      profileData: {
        borderColor: "",
        items: [...Array(0)].map(() => null),
        redirect: "",
      },

      editorMode: true,
      addSocial: false,

      isEditSocial: false,
      item: null,

      selectedSocial: "",

      icon: "",

      isTemplate: false,

      field_names: json["field_names"],
      input_length: json["input_length"],
      placeholders: json["placeholders"],

      cursor: "default",

      setLock: false,
      setUnLock: false,

      // color1: "#ef7d00",
      // color2: "#ef7d00",
    };
  },

  watch: {
    "profileData.items": {
      handler() {
        this.setRedirect();
        if (this.profileData.items !== null) {
          this.$emit("profileData", this.profileData);
          db_user.vSocials.updateSocials(this.userData.uid, this.oItems);
        }
      },
      deep: true,
    },

    "userData.nickname": {
      handler() {
        db_user.vData.updateData(this.userData.uid, {
          nickname: this.userData.nickname,
        });
      },
      deep: true,
    },

    "userData.bio": {
      handler() {
        db_user.vData.updateData(this.userData.uid, { bio: this.userData.bio });
      },
      deep: true,
    },

    user_info: {
      handler() {
        this.userData = this.user_info;
      },
    },

    social_data: {
      handler() {
        this.profileData = this.social_data;
      },
      deep: true,
    },
  },

  computed: {
    oItems() {
      return this.profileData.items.filter((a) => a);
    },
    oItemsLength() {
      return this.oItems.length;
    },
  },

  async created() {
    this.userData = this.user_info;

    this.profileData = this.social_data;

    await this.setSlider();

    if (await this.setRedirect()) {
      this.hidePopUp(true);
    }
  },

  methods: {
    setRedirect() {
      if (this.profileData.items[0] !== null) {
        this.selectedSocial = this.profileData.items[0].social;

        if (this.profileData.redirect) {
          this.profileData.borderColor = "opacity-low";
        }
        return true;
      } else {
        this.selectedSocial = "";
        return false;
      }
    },

    async setSlider() {
      if (this.userData.offline) {
        // this.swiperOption.initialSlide = 1
        this.page = 1;
      } else {
        // this.swiperOption.initialSlide = 0
        this.page = 0;
      }
    },

    setSState(x) {
      var swipe = {
        type: "",
      };
      if (x === "left") {
        swipe.type = "swipeleft";
        return swipe;
      } else {
        swipe.type = "swiperight";
        return swipe;
      }
    },

    async slideState(swipe) {
      if (this.editorMode) {
        this.hidePopUp(false);

        if (swipe.type == "swiperight" && this.userData.offline) {
          this.page = 0;

          const pData = await db_user.setOnline(
            this.userData.uid,
            Object.assign({}, this.userData)
          );

          await this.$emit("setPData", pData);

          this.userData = await Object.assign({}, this.userData, pData.d);
          this.profileData.items = pData.s.data;

          this.setUnLock = true;
          setTimeout(() => (this.setUnLock = false), 2000);
        } else if (swipe.type == "swipeleft" && !this.userData.offline) {
          this.page = 1;
          this.profileData.borderColor = "";
          var pData = await db_user.setOffline(
            this.userData.uid,
            Object.assign({}, this.userData),
            this.oItems
          );

          await this.$emit("setPData", pData);

          this.userData = await Object.assign({}, this.userData, pData.d);
          this.profileData.items = pData.s.data;

          this.setLock = true;
          setTimeout(() => (this.setLock = false), 2000);
        }
        this.hidePopUp(true);
      }
    },

    hidePopUp(value) {
      this.$emit("hidePopUp", value);
    },

    setState(value) {
      this.editorMode = value;

      if (value) {
        this.cursor = "grab";
      } else {
        this.cursor = "default";
      }
    },

    async setImage(image) {
      this.userData.image = await db_storage.addProfilePicture(
        this.userData.uid,
        image
      );
      await db_user.vData.updateData(this.userData.uid, {
        image: this.userData.image,
      });
    },

    setNewItem(value) {
      var item = value;

      if (this.oItemsLength < this.userData.numberOfApps) {
        item["position"] = this.oItemsLength;
        this.profileData.items.splice(this.oItemsLength, 1, item);
      }
    },

    async setItems(items) {
      this.profileData.items = await methods.sortPosition(
        items,
        this.userData.numberOfApps
      );

      // this.$refs.myswiper.$swiper.allowSlideNext = true; //goes to false idk
    },

    async changeRedirect() {
      if (!this.profileData.redirect) {
        this.profileData.redirect = true;
        this.profileData.borderColor = "opacity-low";
      } else {
        this.profileData.redirect = false;
        this.profileData.borderColor = "";
      }

      await db_user.vSocials.updateRedirect(
        this.userData.uid,
        this.profileData.redirect
      );
    },

    logout() {
      this.$emit("logout", true);
    },

    setMenu(value) {
      this.$emit("menu", value);
    },

    setNName() {
      this.userData.nickname = this.$refs.nickname.value;
    },

    setBio() {
      this.userData.bio = this.$refs.bio.value;
    },

    editTile(object) {
      this.item = object.item;
      this.index = object.index;
      this.setEdit(true);
    },



    setEdit(value) {
      if (value) {
        methods.setScrolling("hidden","fixed", "100%");
      } else {
        methods.setScrolling("visible","static", "auto");
      }
      this.isEditSocial = value;
    },

    setAddSocial(value) {
      if (value) {
        methods.setScrolling("hidden","fixed", "100%");
      } else {
        methods.setScrolling("visible","static", "auto");
      }

      this.addSocial = value;
    },

    async handleDelete(index) {
      this.profileData.items.splice(index, 1, null);
      this.profileData.items = await methods.sortPosition(
        this.profileData.items,
        this.userData.numberOfApps
      );
      this.setEdit(false)
    },

    changeItem(item) {
      this.profileData.items[item.position].title = item.title;
      this.profileData.items[item.position].data = item.data;
      this.setEdit(false)
    },
  },
};
</script>

