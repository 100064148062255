<template>
  <div>
    <SocialPopUp :data="data" :color="color" @back="setBack" />
  </div>
</template>

<script>
import SocialPopUp from "./Apps/SocialPopUp.vue";


export default {
  components: {
    SocialPopUp,
  },
  props: ["data", "color"],

  methods: {
    setBack(value) {
      if (value) {
        this.$emit("back", value);
      }
    },
  },
};
</script>