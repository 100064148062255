<template>
  <div class="background" :style="
          'background-image: url(\'../images/amigo_logo_outline_bg.svg\'), ' +
          'linear-gradient(311deg, ' +
          color[0] +
          ',' +
          color[1] +
          ');'
        ">
    <Header />

    <template v-if="state === 1">
      <Step1
        heading="Share an app!"
        button="Select app"
        :socials="socials"
        :color="color"
        @selected="setSelectedSocial"
        @state="setState"
      />
    </template>
    <template v-else-if="state === 2">
      <Step2
        heading="Set App"
        button="Add app"
        :selected_social="selected_social"
        :app_exists="false"
        :color="color"
        @submit="doSubmit"
        @state="setState"
        @delete="doNothing"
      />
    </template>
  </div>
</template>

<script>
import Header from "../../General/Elements/Headers/HeaderAMIGO.vue";
import Step1 from "./SelectSocial.vue";
import Step2 from "./setAppData.vue";

import methods from "../../General/Methods/General.js";

export default {
  components: {
    Header,
    Step1,
    Step2,
  },
  props: ["premium", "view", "color"],
  data: () => {
    return {
      selected_social: {
        info: {
          uid: null,
          title: null,
        },
        fields: {
          options: [],
          option: null,
        },
      },

      changeTitle: false,
      state: 0,
      doRedirect: false,
    };
  },

  async created() {
    this.socials = await methods.getAccountSocials(this.premium, this.view);
    this.setState(1);
  },

  methods: {
    setState(state) {
      if (state === 0) {
        this.$emit("mode");
      } else {
        this.state = state;
      }
    },

    setSelectedSocial(selected_social) {
      this.selected_social = selected_social;
      this.setState(2);
    },

    setOption(value) {
      this.selected_social.option = value;
    },

    setModeFalse() {
      this.state = 0;
      this.selected_social = "";
      this.$emit("mode", false);
    },

    goStep2() {
      if (this.selected_social !== "") {
        this.state = 2;
        this.title = "title";
        this.checkRedirect();
      }
    },

    doSubmit(item) {
      this.$emit("item", item);
      this.setModeFalse();
    },

    doNothing() {
      // method that runs in case something goes wrong, however doens't do anything
    },
  },
};
</script>