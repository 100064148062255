<template>
  <div v-if="!noProfile">
    <div class="add-connect remember" style="zindex: 10">
      <a @click="goRemember(true)" class="button _2 w-inline-block">
        <img src="images/remember.png" loading="lazy" alt="" class="image-17" />
        <div class="button-txt non-mobile">Remember</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
    props: [
        "noProfile"
    ],

    methods: {
        goRemember(value) {
            this.$emit('setRemember', value);
        }
    }
};
</script>
