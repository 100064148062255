<template>
    <div style="width:100%">
        <div class="field-div vertical">
            <div class="paragraph left">{{field_names.url}}</div>
                <div class="account-column w-row">
                    <div class="left-account-column w-col w-col-8">
                        <div>
                            <div class="url-div">
                                <input disabled type="text" class="text-field nofocusborder left w-input" maxlength="15" placeholder="amigo.bio/">
                                <div class="typewriter-div">
                                    <input ref="url" :disabled="update" @change="setUrl()" :value="temp_url" :maxlength="input_length.url" required="true" type="url" class="text-field nofocusborder right w-input" autocorrect="off" autocapitalize="off">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-account-column w-col w-col-4">
                        <div >
                            <a v-if="!update" @click="state(true)" class="submit-button w-button">{{btn_update}}</a>
                            <a v-if="update" @click="state(false)" class="submit-button delete w-button">{{btn_cancel}}</a>                            
                        </div>
                    </div>
                </div>
                <div class="account-column up w-row">
                    <div class="left-account-column w-col w-col-8">
                        <div >
                            <div v-if="error.url!==''" class="error-message left">{{error.url}}</div>
                            <div v-if="error.url==='' & success.url!==''" class="success-message left">{{success.url}}</div>
                        </div>
                    </div>
                    <div class="right-account-column w-col w-col-4"></div>
                </div>
            </div>
            <div v-if="update" class="field-div vertical">
                <div class="paragraph left">{{field_names.confirm_password}}</div>
                    <div class="account-column w-row">
                        <div class="left-account-column w-col w-col-8">
                            <input ref="password" :maxlength="input_length.password" :placeholder="placeholders.password" type="password" class="text-field w-input" required="true">
                        </div>
                        <div class="right-account-column w-col w-col-4">
                            <div>
                                <a @click="updateUrl()" class="submit-button w-button">{{btn_confirm}}</a>
                            </div>
                    </div>
                </div>

                <div class="account-column up w-row">
                    <div class="left-account-column w-col w-col-8">
                        <div >
                            <div v-if="error.password!==''" class="error-message left">{{error.password}}</div>
                            <div v-if="error.password==='' & success.password!==''" class="success-message left">{{success.password}}</div>
                        </div>
                    </div>
                    <div class="right-account-column w-col w-col-4"></div>
                </div>
        </div>
    </div>
</template>

<script>


import db_url from '../../DB/url.js'
import db_auth from '../../DB/auth.js'
import json from '../../GlobalValues.json'

export default {
    props: [
        'url',
        'url_type',
        'withPassword',
        'email',
        'uid',
    ],

    data() {
        return {
            input_length: json["input_length"],
            field_names: json["field_names"],
            placeholders: json["placeholders"],
            
            btn_confirm: "Confirm",
            btn_update: "Update",
            btn_cancel: "Cancel",

            error: {
                url: "",
                password: "",
            },

            success: {
                url: "",
                password: ""
            },

            temp_url: "",
            update: false,

            

        }
        
    },

    created() {
        this.temp_url = this.url;

    },

    methods: {

        async updateUrl() {
            var user = await db_auth.login(this.email, this.$refs.password.value)
            .catch((err) => {
                this.error.password = err.message;
            })

            if (user) {
                

                if(this.url_type === 'product'){
                    
                    await db_url.setUrl(this.temp_url,this.uid,'internet','none')
                    .catch((err) => {
                        this.error.url = err;
                    })
                } else {

                    await db_url.updateUrl(this.url, this.temp_url,this.uid,'internet','none')
                    .catch((err) => {
                        this.error.url = err;
                    })

                }

                
                
                this.$emit('url', this.temp_url)
                this.success.url = "Your url is changed!"
                this.update = false;
                console.log(this.temp_url)
                this.$router.push('/' + this.temp_url)

            } else {
                this.error.password = "Sorry wrong password!"
            }
        
        },
        

        async setUrl() {
        
            if (this.url !== this.$refs.url.value) {
                if ((await this.checkUrl(this.$refs.url.value.toLowerCase())) && (!this.withPassword)) {
                    
                    this.$emit('url', this.temp_url)
                    return;
                }
                
                if (this.error.url === '') {
                    return true;
                } else {
                    return false;
                }
 
            } else {
                this.error.url = "Sorry this is already your url"
                return false;
            }

        },

        async checkUrl(url){
            const option = await db_url.available(url, null);
            if (option === 1 || option === 2) {
                this.error.url = "";
                this.success.url = "https://amigo.bio/" + url + " is available";
            } else if(option === 3) {
                this.success.url = ""
                this.error.url = "https://amigo.bio/" + url + " is already taken";
            } else if (option === 4) {
                this.success.url = ""
                this.error.url = "https://amigo.bio/" + url + " is not possible. Only numbers, letters, hypens, periods, spaces and underscores are allowed";
            }
            this.temp_url = url;
        },

        async state(value) {
            if (await this.setUrl()){
                this.update = value;
                this.$emit('update', value)
            }
                
        }

    }


};
</script>