<template>
  <div class="social-block">
    <div class="grid-block">
      <template v-if="borderColor !== ''">
        <p class="txt small marge-bottom">
          Your profile will be redirected to: {{ items[0].title }}<br />
        </p>
      </template>
      <draggable
        :move="checkMove"
        :forceFallback="true"
        v-model="items"
        class="w-layout-grid public-pf"
        draggable=".drag"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="item != null ? 'drag' : 'cursor-disabled'"
        >
          <Apps
          :item="item"
          :index="index"
          :setOpacity="borderColor"
          :offline="!offline"
          :color="color"
          @edit="editTile"
          @newSocial="addSocial()"
          />

        </div>
      </draggable>

      <div v-if="numberOfItems < numberOfApps && !offline" class="button-div">
        <a @click="addSocial()" class="button">
          <img src="images/add-cross.png" loading="lazy" class="image-17" />
        </a>
      </div>
    </div>
  </div>
</template>



<script>
import draggable from "vuedraggable";

import Tile from "../General/Elements/AppConfig/ConfigTile.vue";
import EmptyTile from "../General/Elements/Apps/EmptyTile.vue";

import methods from "../General/Methods/General.js";

import Apps from "../General/Elements/Apps/App (Configuring).vue"

const MAX_TILES = 12;

export default {
  components: {
    draggable,
    Tile,
    EmptyTile,
    Apps,
  },
  data: () => {
    return {
      addTo: null,
      hovers: -1,
      drag: false,
      other: null,
    };
  },
  props: [
    "mainItems",
    "borderColor",
    "numberOfItems",
    "uid",
    "offline",
    "color",
    "numberOfApps",
  ],

  computed: {
    items: {
      get: function () {
        return this.mainItems;
      },

      set: function (value) {
        this.$emit("itemUpdate", value);
      },
    },
  },


  methods: {
    async checkMove(e) {
      return this.isDraggable(e);
    },
    async isDraggable(context) {
      const newIndex = context.relatedContext.index;
      if (this.items[newIndex]?.social === undefined) {
        return false;
      }

      const oldIndex = context.draggedContext.index;
      if (this.items[oldIndex]?.social === undefined) {
        return false;
      }
    },

    addSocial() {
      this.$emit("newSocial", true);
    },

    editTile(object) {
      this.$emit("editTile", object);
    },

    async setItems() {
      this.$emit("itemUpdate", this.items);
    },

   
  },
};
</script>
