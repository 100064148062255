<template>
  <div class="contact-popup">
    <div class="vcard-chrome">
      <div class="div-block-70">
        <div class="logo-pop-up border">
          <img
            src="images/amigo-zwart-fav.png"
            loading="lazy"
            alt=""
            class="amigo-logo"
          />
          <div class="logo-text black">amigo</div>
        </div>
      </div>
      <div class="div-block-71">
        <p class="text black">{{ title }}</p>
        <p class="paragraph black">
          {{ text }}
        </p>
      </div>
      <a @click="doCopy" class="submit-button w-button">{{ button }}</a>
      <a @click="close" class="close-add-tile rightmarge">
        <img
          src="images/close.png"
          loading="lazy"
          alt="AMIGO CONNECTED"
          class="image-12"
        />
      </a>
    </div>
    <template v-if="copy">
      <Copy text1="" :text2="link" :copy="true" @back="close()" />
    </template>
  </div>
</template>


<script>
import Copy from "./Alert.vue";

export default {
  components: {
    Copy,
  },
  props: ["link", "title", "text", "button"],
  data() {
    return {
      // title: 'Sorry..',
      // text: 'We have a small issue with Chrome and iOS. You can download the contact in your safari browser. <br><br>Go to Safari and paste the link in your url',
      // button: 'Copy link',

      copy: false,
    };
  },

  created() {
    document.getElementsByClassName("body")[0].style.overflow = "hidden";
    document.getElementsByClassName("body")[0].style.height = "100vh";
  },


  methods: {
    doCopy() {
      this.copy = true;
    },

    close() {
      document.getElementsByClassName("body")[0].style.overflow = "visible";
      document.getElementsByClassName("body")[0].style.height = "auto";
      this.$emit("close", true);
    },
  },
};
</script>