import {db, firebase} from '../../firebase'
import json from '../GlobalValues.json'
import validate from '../Methods/Validate.js'
 
export default {

    async getData(url){
        var data = await db.collection(json['collections'].urls).doc(url).get()
        .then(async (doc) => {
            if (doc.exists) {
                return doc.data();
            } else {
                return null
            }
        })
        .catch((err) => {
            throw err.message;
        })


        var p = await db.collection(json['collections'].urls).doc(url).collection(json['collections'].data).doc(json['docs'].private).get()
        .then(async (doc) => {
            if (doc.exists) {
                return doc.data();
            } else {
                return null
            }
        })
        .catch((err) => {
            throw err.message;
        })
        data = Object.assign({}, data, p);

        return data
    },


    async getCode(url) {

        return await db.collection(json['collections'].urls).doc(url).collection(json['collections'].data).doc(json['docs'].private).get()
        .then(async (doc) => {
            if (doc.exists) {
                return doc.data().code;
            } else {
                return null
            }
        })
        .catch((err) => {
            throw err.message;
        })
    },

   
    async setUrl(url,uid,type,code) {

        await db.collection(json['collections'].urls).doc(url).set({
            uid: uid,
        })
        .catch((err) => {
            throw err.message;
        })



        await db.collection(json['collections'].urls).doc(url).collection(json['collections'].data).doc(json['docs'].private).set({
            type: type,
            code: code
        })
        .catch(async (err) => {
            await db.collection(json['collections'].urls).doc(url).delete();
            throw err.message;
        })

        if(type==='product') {
            db.collection(json['collections'].urls).doc(url).update({
                type: firebase.firestore.FieldValue.delete(),
                code: firebase.firestore.FieldValue.delete()
            })
            .catch(async (err) => {
                await db.collection(json['collections'].urls).doc(url).delete();
                db.collection(json['collections'].urls).doc(url).collection(json['collections'].data).doc(json['docs'].private).delete();
                throw err.message;
            })
        }
            




  
    },

    async updateUrl(oldUrl, url,uid,type,code) {
        await this.setUrl(url,uid,type,code)
        .catch((err) => {
            throw err;
        })
        
        await this.modifyUrl(oldUrl, code, type)
        .catch((err) => {
            throw err;
        })
    },


    async updateUID(url,uid) {
      
        await db.collection(json['collections'].urls).doc(url).update({
            uid: uid,
        })
        .catch((err) => {
            throw err.message;
        })
    },


    async available(url, activationcode) {
        /*
            1 = url available
            2 = activate product
            3 = not available
            4 = url not valid
        */
            if (await validate.url(url)) {
                return await db.collection(json['collections'].urls).doc(url).get()
                .then(doc => {
                    if (doc.exists) {
                        if ((doc.data().uid === 'reserved') && (activationcode === doc.data().code)) {
                            return 2;
                        } else {
                            return 3;
                        }
                    } else {
                        return 1;
                    }
                })   
            } else {
                return 4;
            }
            
        },


        async getPublic(url) {
            return await db.collection(json['collections'].urls).doc(url).get()
            .then(doc => {
                if (doc.exists) {
                    var data = doc.data();
                    data.url = url
                    return data;
                } else {
                    return null;
                }
            })
            .catch(()=>{
                throw "Sorry something went wrong!"
            })
        },

        async getPrivate(url) {
           
            return await db.collection(json['collections'].urls).doc(url).collection(json['collections'].data).doc(json['docs'].private).get()
            .then(doc => {
                if (doc.exists) {
                    var data = doc.data();
                    return data;
                } else {
                    return null;
                }
            })
            .catch((err)=>{
                throw err.message;
            })
        },


        async getAllUrls(uid) {
  
            return await db.collection(json['collections'].urls).where('uid', '==', uid).get()
            .then((urls) => {
                return urls;
            })
            .catch((err) => {
                throw err.message;
            })
        },

        async resetUrl(uid) {
      
            const all_urls = await this.getAllUrls(uid)
            .catch((err) => {
                throw err;
            })



            await all_urls.forEach(async (doc) => {
                var privatedata = await this.getPrivate(doc.id)
                await this.modifyUrl(doc.id, privatedata.code, privatedata.type)
                .catch((err) => {
                    throw err;
                })
                return
            });

            
        },


        async modifyUrl(url, code, type){
  
            if (type === 'product') {

                await db.collection(json['collections'].urls).doc(url).update({
                    uid: "reserved",
                    type: type,
                    code: code
                })
                .catch((err) => {
                    throw err.message;
                })
      
                await this.deletePrivate(url)
                .catch((err) => {
                    throw err;
                })
        

                
            } else {

                await this.deleteUrl(url)
                .catch((err) => {
                    throw err;
                })

                
                
            }

        },

        async deleteUrl(url) {
            await this.deletePrivate(url)
            .catch((err) => {
                throw err;
            })

            await db.collection(json['collections'].urls).doc(url).delete()
            .catch((err) => {
                throw err.message;
            })
        },

        async deletePrivate(url) {
            await db.collection(json['collections'].urls).doc(url).collection(json['collections'].data).doc(json['docs'].private).delete()
            .catch((err) => {
                throw err.message;
            })
        },

    
}
   


   