<template>
  <div class="group-div">
    <div class="tile" :class="[item.social]">
      <div
        v-if="hovers === index"
        :style="'background-color:' + color_rgba()"
        class="icon-hover draggable"
      >
        <img src="images/option-white.png" loading="lazy" width="36" />
      </div>
      <div @touchstart="edit(item)" @click="edit(item)" class="delete-tile-div">
        <div class="change">
          <img
            src="images/edit-button-white.png"
            loading="lazy"
            class="edit-img"
          />
        </div>
      </div>
    </div>
    <div class="txt-div">
      <div class="white">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import methods from "../../Methods/General.js";

export default {
  components: {},
  props: ["item", "hovers", "index", "color"],

  methods: {
    edit(item) {
      var object = {
        item: item,
        index: this.index,
      };
      this.$emit("edit", object);
    },
    color_rgba() {
      return methods.hexToRgb(this.color, 0.4);
    },
  },
};
</script>