<template>
  <div
    class="background menu height"
    :style="
          'background:' +
          'linear-gradient(311deg, ' +
          color[0] +
          ',' +
          color[1] +
          ');'
        ">
    <div class="content">
      <div>
        <div class="header-column w-row">
          <div class="left-col menu w-col w-col-9">
            <LeftHeader />
          </div>
          <div class="right-col close-add-tile w-col w-col-3">
            <div class="settings-div">
              <a @click="handleClose()" class="link-block w-inline-block">
                <img
                  src="images/close-white.png"
                  loading="lazy"
                  alt=""
                  class="settings-image"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="menu-upper">
          <a
            href="https://amigo-connected.com"
            target="_blank"
            class="menu-items w-inline-block"
            ><img
              src="images/logo-amigo-icoon-wit-hq.png"
              loading="lazy"
              alt=""
              class="menu-icon"
            />
            <h1 class="heading-4 white left">Information</h1>
          </a>
        </div>
      </div>
      <div class="menu-bottom">
        <a href="#/login" class="menu-items _2">
          <img
            src="images/icon-log-out.svg"
            loading="lazy"
            alt=""
            class="menu-icon small"
          />
          <h1 class="heading6 white left">Login</h1>
        </a>
        <a
          href="https://shop.amigo-connected.com"
          target="_blank"
          class="menu-items _2"
        >
          <img
            src="images/icon-shopping-cart.svg"
            loading="lazy"
            alt=""
            class="menu-icon"
          />
          <h1 class="heading6 white left">Webshop</h1>
        </a>
      </div>
    </div>
  </div>
</template>




<script>
import LeftHeader from "../General/Elements/Headers/LeftHeader.vue";

export default {
  components: {
    LeftHeader,
  },
  data() {
    return {};
  },
  props: ["showMenu", "color"],
  computed: {},
  methods: {
    handleClose() {
      if (this.showMenu) {
        this.$emit("showMenu", false);
      }
    },
  },
};
</script>
