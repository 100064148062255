<template>
  <div class="tile_newsocial customize click">
    <div class="tile">
        <Icon :social="item.social" :colors_arr="color[2].icons"/>
    </div>
    <div class="txt-div">
      <div class="white">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import Icon from "./AppIcon.vue"

export default {
    components: {
        Icon,
    },
  props: ["item", "color"],

  data: () => {
    return {

    };
  },

  methods: {

  },
};
</script>