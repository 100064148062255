import Vue from 'vue'
import VueRouter from 'vue-router';
// import axios from 'axios'
import VueQRCodeComponent from 'vue-qr-generator'




import App from './App.vue'
// import Configure from './Configuration/Main.vue'
import Contacts from './Contacts/Overview.vue'

import Login from './General/Login.vue'
import Remember from './Contacts/Remember.vue'

import Activation from './StartJourney/Activation.vue'
import NoProduct from './StartJourney/NoProduct.vue'

import Reset from './General/ResetPass.vue'
import VerifyPass from './General/NewPassword.vue'

import QR from './Account/QR.vue'

import main from './General/Main.vue'

import weblink from './StartJourney/WebsiteLink.vue'


import VueClipboard from 'vue-clipboard2'

import VueGtag from "vue-gtag";

import VueTouch from 'vue-touch'



Vue.use(VueGtag, {
  config: { 
    id: "G-31HWMLCK4F"
  }
});





VueTouch.config.swipe = {
  direction: 'horizontal'
}
Vue.use(VueTouch)

import LottieAnimation from 'lottie-vuejs' // import lottie-vuejs

Vue.use(LottieAnimation); 





// import Menu from './Menu/LoggedOut.vue'


 
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)


Vue.use(VueRouter);

// Vue.prototype.$http = axios

Vue.component('qr-code', VueQRCodeComponent)


Vue.prototype.$color = 'green'

const paths = [
  
  
  
  { path: '/contacts', component: Contacts },
  { path: '/login', component: Login },

  { path: '/added', component: Remember },
  { path: '/activation*', component: Activation },
  { path: '/register', component: NoProduct },
  { path: '/reset-password', component: Reset },
  { path: '/verification-reset*', component: VerifyPass },
  { path: '/qr-code', component: QR },
  {path: '/register2*', component: weblink},
  { path: '/*', component: main},
  
  
];

const router = new VueRouter({
  mode: 'hash',
  routes: paths,
  base: process.env.BASE_URL,

  
});

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App),
});
