<template>
  <div class="header-card">
    <div class="_100 w-row">
      <div class="left-col direction w-col w-col-6">
        <div class="tile small">
          <Icon :social="social.uid" :colors_arr="color[2].icons"/>
        </div>
        <div class="icon-title marge">{{ initial_title }}</div>
      </div>
      <div class="right-col w-col w-col-6">
        <div class="socialname-div">
          <div>
            <div class="open-app-txt">App name:</div>
            <input
              ref="title"
              type="text"
              :maxlength="input_length.title"
              :placeholder="initial_title"
              :value="getTitle()"
              class="tile-title-txt w-input"
              @change="changeTitle()"
              @keyup="changeTitle()"
            />
          </div>

          <div class="edit-link-block w-inline-block">
            <img src="images/draw.png" loading="lazy" class="_15x15" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "../../GlobalValues.json";
import methods from "../../Methods/General.js";

import Icon from "../Apps/AppIcon.vue"

export default {
  components: {
    Icon,
  },
  props: ["social", "isNewApp", "color"],



  async created() {
    this.initial_title = await methods.getSocialTitle(this.social.uid);
  },


  data: () => {
    return {
      input_length: json["input_length"],

      initial_title: null,

    };
  },

  

  
  methods: {


    changeTitle() {
      this.$emit("changeTitle", this.$refs.title.value);
    },

    length() {
      return this.$refs.title.value.length;
    },

    getTitle() {
      if (this.isNewApp) {
        return "";
      } else {
        return this.social.title;
      }
    },
  },
};
</script>