<template>
  <div class="add-your-profile">
    <h1 class="heading-4">{{heading}}</h1>
    <a @click="closeWindow()" class="close-add-tile inheader w-inline-block">
      <img src="images/close.png" loading="lazy" class="image-12" />
    </a>
  </div>
</template>

<script>

export default {
  props: ["heading"],

  methods: {
    closeWindow() {
      this.$emit("state", 0);
    },
  }
}


</script>