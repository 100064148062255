<template>
  <div>
    <router-view></router-view>
  </div>
</template>


<script>
import { analytics, logEvent } from './firebase/index'

export default {
  name: 'app',
  created() {
    logEvent(analytics, 'initial_screen_view', {
      url: window.location.href
    });
  }
}


</script>



