<template>
  <div>
    <template v-if="loggedIn & (exists === null)">
      <PopUP />
    </template>
    <template v-else-if="loggedIn">
      <div class="background _100vh">
        <Header />
        <div class="header-logout">
          <div class="columns-2 w-row">
            <div class="w-col w-col-6">
              <div class="text-div _1">
                <a @click="goBack" class="button small w-inline-block">
                  <img
                    src="images/go-back.png"
                    loading="lazy"
                    sizes="(max-width: 991px) 20px, (max-width: 1279px) 2vw, (max-width: 1439px) 20px, 1vw"
                    srcset="
                      images/go-back-p-500.png 500w,
                      images/go-back.png       512w
                    "
                    alt=""
                    class="image-18"
                  />
                </a>
              </div>
            </div>
            <div class="column w-col w-col-6">
              <div class="button-block logout"></div>
            </div>
          </div>
        </div>

        <div class="page-body-2">
          <div class="pop-up-block">
            <img
              src="images/shining.png"
              loading="lazy"
              width="51"
              sizes="(max-width: 1279px) 51px, (max-width: 1439px) 4vw, 51px"
              srcset="images/shining-p-500.png 500w, images/shining.png 512w"
            />
            <div class="remember-div">
              <p class="text">You {{ exists }} saved</p>
              <p class="text">{{ name }}</p>
              <p class="text">to your contacts</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Login :name="this.name" :uid="this.uid" @isLoggedIn="setLogin" />
    </template>
  </div>
</template>

<script>
import db_user from "../General/DB/user/main.js";

import Login from "./Login.vue";

import Header from "../General/Elements/Headers/Header1.vue";

import PopUP from "../General/WaitPage.vue";

export default {
  components: {
    Login,
    Header,
    PopUP,
  },
  props: ["name", "uid", "user", "score"],
  data: () => {
    return {
      exists: null,
      loggedIn: false,
      variablesSet: false,
      addedValue: 0,

      contact: {
        name: "",
        uid: "",
      },
    };
  },

  async created() {
    if (this.user) {
      this.loggedIn = true;
      this.rememberContact();
    }
  },

  methods: {
    async rememberContact() {
      const contacts = await db_user.contacts.getContacts(this.user.uid);

      if (contacts !== null) {
        const contact = { name: this.name, uid: this.uid, score: this.score };
        if (
          !(await db_user.contacts.isExistingContact(contacts, contact.uid))
        ) {
          console.log(contact.uid);

          if (await db_user.contacts.addContact(this.user.uid, contact)) {
            const score = contact.score + 1;

            await db_user.vData
              .updateData(contact.uid, { score: score })
              .catch(async () => {
                await db_user.contacts.deleteContact(this.user.uid, contact);
                return;
              });
            this.$emit("score", score);
          }
          this.exists = "";
        } else {
          this.exists = "already";
        }
      }
    },

    goBack() {
      this.$emit("goBack", this.addedValue);
    },

    setLogin(value) {
      if (value) {
        this.loggedIn = value;
        this.rememberContact();
      }
    },
  },
};
</script>